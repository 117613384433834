import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup,Validators } from '@angular/forms';
import { faUser, 
  IconDefinition, 
  faLock,
  faArrowAltCircleRight,
  faEnvelope,
  faIdCard, 
  faLocationDot,
  faBuilding,
  faFlag,
  faTruck,
  faCalendarDays,
  faCircleMinus
 } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { TranslateService } from '@ngx-translate/core';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { UsersService } from '@shared/services/users-service';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrl: './multi-step-form.component.css'
})
export class MultiStepFormComponent implements OnInit, AfterViewInit {
  public SearchCountryField=SearchCountryField;
  public CountryISO=CountryISO;
  public PhoneNumberFormat=PhoneNumberFormat;

  public userIcon: IconDefinition= faUser;
  public addressIcon:IconDefinition=faLocationDot;
  public cityIcon:IconDefinition=faBuilding;
  public calendarIcon:IconDefinition=faCalendarDays;
  public regionIcon:IconDefinition=faFlag;
  public minusIcon:IconDefinition=faCircleMinus;
  public postalCodeIcon:IconDefinition=faTruck;
  public emailIcon: IconDefinition= faEnvelope;
  public idIcon: IconDefinition= faIdCard;
  public lockIcon: IconDefinition= faLock;
  public rightArrow: IconDefinition= faArrowAltCircleRight;
  public firstMessageContinue:boolean=false;
  public termsAccepted:any=false;
  public preferredLang:string='es';
  public termsAaccept:boolean=false;
  public termsBaccept:boolean=false;
  public finalAccept:boolean=false;
  public lang:string='';
  public currentStep = 1;
  public maxSteps = 4;
  public userCreated = false;

  public registerFormGroup:FormGroup = new FormGroup({});
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,  
    center:false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    margin:16,
    navSpeed: 700,
    navText: ['<<', '>>'],
    responsive: {
      0: {
        items: 1,
      }
    },
    nav: true
  };

  constructor(private router:Router, private translateService:TranslateService, private usersService: UsersService, private alertService:AlertService){}

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'EN';
    this.preferredLang = this.lang.toLowerCase();
    this.registerFormGroup = new FormGroup(
      {
        email: new FormControl('',[
          Validators.required,
          Validators.email
      ]),
        userName: new FormControl('',[
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20)
        ]),
        dni: new FormControl('',[
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern("^[0-9]*$")
        ]),
        dni_type:new FormControl('CC',[
          Validators.required
        ]),
        familyInfo: new FormArray([]),
        address:new FormControl('',
          [Validators.required]
        ),
        cellphone:new FormControl('',[
          Validators.required
        ]),
        // phone:new FormControl(''),
        firstName: new FormControl('',[
          Validators.required,
          Validators.maxLength(20)
        ]),
        secondName: new FormControl('',[]),
        firstSurname: new FormControl('',[
          Validators.required,
          Validators.maxLength(20)
        ]),
        secondSurname: new FormControl('',[]),
        gender: new FormControl('',[
          Validators.required
        ]),
        birthDay: new FormControl('',[
          Validators.required
        ]),
        city: new FormControl('',[
          Validators.required,
          Validators.maxLength(30)
        ]),
        department: new FormControl('',[
          Validators.required,
          Validators.maxLength(30)
        ]),

        // postal: new FormControl('',[
        //   Validators.required,
        //   Validators.pattern("^[0-9]*$")
        // ]),

        country: new FormControl('',[
          Validators.required,
          Validators.maxLength(30)
        ]),

        foundUs: new FormControl('',[
          Validators.maxLength(500)
        ]),
      }
    )
  }

  ngAfterViewInit(): void {
    AOS.init({once:false});
    document.querySelectorAll('img')
    .forEach((img) =>
      img.addEventListener('load', () =>
          AOS.refresh())
    );
  }

  getFullName(){
    try{
      if(this.preferredLang === 'es'){
        // @ts-ignore: Object is possibly 'null'.
        let message = `Yo, ${this.registerFormGroup.get("firstName").value} ${this.registerFormGroup.get("firstSurname").value} identificado con el numero de documento  ${this.registerFormGroup.get("dni").value}`;
        return message;
      }else{
        // @ts-ignore: Object is possibly 'null'.
        let message = `Me, ${this.registerFormGroup.get("firstName").value} ${this.registerFormGroup.get("firstSurname").value} identified with the document number ${this.registerFormGroup.get("dni").value}`;
        return message;
      }

    }catch(err){
      return '';
    }


  }

  nextStep(currentStep:string | number, registerFormGroup: any):void{
    let registerFormControls=registerFormGroup.controls;
  
    if(currentStep === 1){
      if(registerFormControls['email'].errors !== null && Object.keys(registerFormControls['email'].errors).length > 0){
        if(Object.keys(registerFormControls['email'].errors).includes('email')){
          this.translateService.get('others.errorMessages.invalidEmail').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['email'].errors).includes('required')){
          this.translateService.get('others.errorMessages.requiredEmail').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        }
      };

      if(registerFormControls['userName'].errors !== null && Object.keys(registerFormControls['userName'].errors).length > 0){
        if(Object.keys(registerFormControls['userName'].errors).includes('minlength')){
          this.translateService.get('others.errorMessages.usernameMinLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['userName'].errors['minlength'].requiredLength),'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['userName'].errors).includes('maxlength')){
          this.translateService.get('others.errorMessages.usernameMaxLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['userName'].errors['maxlength'].requiredLength),'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['userName'].errors).includes('required')){
          this.translateService.get('others.errorMessages.usernameRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        }
      };

      if(registerFormControls['dni'].errors !== null && Object.keys(registerFormControls['dni'].errors).length > 0){
        if(Object.keys(registerFormControls['dni'].errors).includes('minlength')){
          this.translateService.get('others.errorMessages.dniMinLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['dni'].errors['minlength'].requiredLength),'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['dni'].errors).includes('maxlength')){
          this.translateService.get('others.errorMessages.dniMaxLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['dni'].errors['maxlength'].requiredLength),'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['dni'].errors).includes('required')){
          this.translateService.get('others.errorMessages.dniRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['dni'].errors).includes('pattern')){
          this.translateService.get('others.errorMessages.dniOnlyNumbers').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        }
      }
    }else if(currentStep === 2){
      let familyArray = (<FormArray>this.registerFormGroup.get('familyInfo'));
      for(let i=0; i < familyArray.length; i++ ){

          if(!familyArray.at(i).valid){
            this.translateService.get('others.errorMessages.invalidFamilyMember').subscribe((translation: string) => {
              this.alertService.emitAlert(translation,'WARNNING');
            });
      
            return;
          }
        
      }
    }else if(currentStep === 3){
      if(registerFormControls['firstName'].errors !== null && Object.keys(registerFormControls['firstName'].errors).length > 0){
        if(Object.keys(registerFormControls['firstName'].errors).includes('required')){
          this.translateService.get('others.errorMessages.firstNameRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['firstName'].errors).includes('maxlength')){
          this.translateService.get('others.errorMessages.firstNameMaxLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['firstName'].errors['maxlength'].requiredLength),'WARNNING');
          });
          return;
        };
      };

      if(registerFormControls['firstSurname'].errors !== null && Object.keys(registerFormControls['firstSurname'].errors).length > 0){
        if(Object.keys(registerFormControls['firstSurname'].errors).includes('required')){
          this.translateService.get('others.errorMessages.surNameRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

        if(Object.keys(registerFormControls['firstSurname'].errors).includes('maxlength')){
          this.translateService.get('others.errorMessages.surNameMaxLenght').subscribe((translation: string) => {
            this.alertService.emitAlert(translation.replace('%',registerFormControls['firstSurname'].errors['maxlength'].requiredLength),'WARNNING');
          });
          return;
        };
      };

      if(registerFormControls['gender'].errors !== null && Object.keys(registerFormControls['gender'].errors).length > 0){
        if(Object.keys(registerFormControls['gender'].errors).includes('required')){
          this.translateService.get('others.errorMessages.genderRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      if(registerFormControls['birthDay'].errors !== null && Object.keys(registerFormControls['birthDay'].errors).length > 0){
        if(Object.keys(registerFormControls['birthDay'].errors).includes('required')){
          this.translateService.get('others.errorMessages.birthDayRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      if(registerFormControls['address'].errors !== null && Object.keys(registerFormControls['address'].errors).length > 0){
        if(Object.keys(registerFormControls['address'].errors).includes('required')){
          this.translateService.get('others.errorMessages.addressRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      if(registerFormControls['city'].errors !== null && Object.keys(registerFormControls['city'].errors).length > 0){
        if(Object.keys(registerFormControls['city'].errors).includes('required')){
          this.translateService.get('others.errorMessages.cityRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      if(registerFormControls['department'].errors !== null && Object.keys(registerFormControls['department'].errors).length > 0){
        if(Object.keys(registerFormControls['department'].errors).includes('required')){
          this.translateService.get('others.errorMessages.departmentRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      if(registerFormControls['country'].errors !== null && Object.keys(registerFormControls['country'].errors).length > 0){
        if(Object.keys(registerFormControls['country'].errors).includes('required')){
          this.translateService.get('others.errorMessages.countryRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

      // if(registerFormControls['postal'].errors !== null && Object.keys(registerFormControls['postal'].errors).length > 0){
      //   if(Object.keys(registerFormControls['postal'].errors).includes('required')){
      //     this.translateService.get('others.errorMessages.postalRequired').subscribe((translation: string) => {
      //       this.alertService.emitAlert(translation,'WARNNING');
      //     });
      //     return;
      //   };

      //   if(Object.keys(registerFormControls['postal'].errors).includes('pattern')){
      //     this.translateService.get('others.errorMessages.postalOnlyNumbers').subscribe((translation: string) => {
      //       this.alertService.emitAlert(translation,'WARNNING');
      //     });
      //     return;
      //   }
      // }

      if(registerFormControls['cellphone'].errors !== null && Object.keys(registerFormControls['cellphone'].errors).length > 0){
        if(Object.keys(registerFormControls['cellphone'].errors).includes('required')){
          this.translateService.get('others.errorMessages.cellphoneRequired').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'WARNNING');
          });
          return;
        };

      };

    }

    if(this.currentStep === this.maxSteps){
      return;
    };

    this.currentStep++;
  }

  previousStep():void{
    if(this.currentStep === 1){
      return;
    }

    this.currentStep--;
  }

  addFamilyMember():void{
    let familyArray = (<FormArray>this.registerFormGroup.get('familyInfo'));
    if(familyArray.length >= 5){
      this.translateService.get('others.errorMessages.maxFamilyMembers').subscribe((translation: string) => {
        this.alertService.emitAlert(translation,'WARNNING');
      });

      return;
    };

    let member:FormGroup= new FormGroup({
      name: new FormControl('',[
        Validators.required , 
        Validators.minLength(6),
        Validators.maxLength(30),
      ]),
      email: new FormControl('',[
        Validators.required,
        Validators.email
      ]),
      relation: new FormControl('',[Validators.required])
    })
    

    familyArray.push(member);
  }

  removeFamilyMember(i:number):void{
    let familyArray = (<FormArray>this.registerFormGroup.get('familyInfo'));
    familyArray.removeAt(i);
  }

  getClass(i:number):string{
    let customClass='';
    
    switch(i){
      case 0:
        customClass='mfs-green'
        break;
      case 1:
          customClass='mfs-pink'
          break;
      case 2:
        customClass='mfs-yellow'
        break;
      case 3:
          customClass='mfs-blue'
          break;
      case 4:
        customClass='mfs-orange'
        break;
    }

    return customClass;

  }

  registerUser():void{
    if(!this.registerFormGroup.valid){
      this.translateService.get('others.errorMessages.invalidForm').subscribe((translation: string) => {
        this.alertService.emitAlert(translation,'WARNNING');
      });
      return;
    };
    
    let userData:any={
        firstName:this.registerFormGroup.controls['firstName'].value,
        secondName:this.registerFormGroup.controls['secondName'].value,
        surname:this.registerFormGroup.controls['firstSurname'].value,
        secondSurname:this.registerFormGroup.controls['secondSurname'].value,
        gender:this.registerFormGroup.controls['gender'].value,
        birthday:this.registerFormGroup.controls['birthDay'].value,
        address:this.registerFormGroup.controls['address'].value,
        city:this.registerFormGroup.controls['city'].value,
        department:this.registerFormGroup.controls['department'].value,
        country:this.registerFormGroup.controls['country'].value,
        phoneNumber:this.registerFormGroup.controls['cellphone'].value['number'],
        phoneNumberCode:this.registerFormGroup.controls['cellphone'].value['dialCode'],
        extraMetadata:{},
        familyInfo: (<FormArray>this.registerFormGroup.get('familyInfo')).controls
        .filter(control => control !== null)
        .map(control => {
          return {
            name: control.get('name')?.value ?? '',
            email: control.get('email')?.value ?? '',
            relation: control.get('relation')?.value ?? ''
          };
        }),
        idDocument:this.registerFormGroup.controls['dni'].value,
        idDocumentType:this.registerFormGroup.controls['dni_type'].value,
        email:this.registerFormGroup.controls['email'].value,

        extraData:{
          userName:this.registerFormGroup.controls['userName'].value,
          //  cellphone:{ 
          //   "number": this.registerFormGroup.controls['cellphone'].value['number'],
          //   "code": this.registerFormGroup.controls['cellphone'].value['dialCode'],
          // }
        },
        termsAndConditions:true,
        habeasData:true
    };

    this.usersService.registerUser(userData).subscribe({
      next: (response) => {
        this.userCreated = true;
      },
      error: (error) => {
        try{
          error = JSON.parse(error.message);

          if(error.message === 'userMailAlreadyRegisteredCognito' || error.message === 'userMailAlreadyRegisteredDb'){
            this.translateService.get('others.errorMessages.emailExists').subscribe((translation: string) => {
              this.alertService.emitAlert(translation,'ERROR',error);
            });
            this.userCreated = false;
            return;
          }
  
          if(error.message === 'userIdAlreadyRegistered'){
            this.translateService.get('others.errorMessages.documentExists').subscribe((translation: string) => {
              this.alertService.emitAlert(translation,'ERROR',error);
            });
            this.userCreated = false;
            return;
          }
        }catch(err){
          this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
            this.alertService.emitAlert(translation,'ERROR',error);
          });
  
          this.userCreated = false;
          return;
        }

        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          this.alertService.emitAlert(translation,'ERROR',error);
        });

        this.userCreated = false;

      },
    });
  }

  redirectToLogin():void{
    this.router.navigate(['/','login']);
  }



  get familyInfoControls() {
    return (this.registerFormGroup.get('familyInfo') as FormArray).controls;
  }


}
