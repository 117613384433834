// Family card component ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

// Imports =====================================================================================================
import { Component, Input, OnInit } from '@angular/core';

// Interfaces/models ===========================================================================================
import { IFamilyMember } from '@core/models/IFamilyMember';

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-family-card',
  templateUrl: './family-card.component.html',
  styleUrl: './family-card.component.css'
})
export class FamilyCardComponent implements OnInit{
  //Input Properties ========================================================================================
  @Input() familyMember!:any;
  @Input() cardColor!:'family-pink'|'family-green'|'family-yellow'|'family-orange'|'family-blue';

  //General Properties ========================================================================================
  //---

  constructor(){}

  // LifeCycles ================================================================================================
  ngOnInit(): void {}

  // CustomFuntions ============================================================================================
  //---

}
