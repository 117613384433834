<!-- toolbars  html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->

<div class="toolbar-container">
    <ul class="toolbar-list list-group">
        <!-- Each posible action in the toolbar (Defined on ts) -->
        <ng-container *ngFor="let option of sideBarOptions">
            <li *ngIf="option.show" (click)="toolBarRedirect(option)">
            <button mat-mini-fab [matBadge]="option?.matBadge !== null && option?.matBadge !== undefined ? option.matBadge : null" matBadgeColor="warn">
                    <mat-icon class="toolbar-option-icon">{{option.materialIcon}}</mat-icon>
                </button>

                <p>{{ 'toolbar.options.' + option.tooltip.toLowerCase() | translate }}</p>

            </li>
        </ng-container>


        <li (click)="logOut()">
            <button mat-mini-fab >
                <mat-icon class="toolbar-option-icon">logout</mat-icon>
            </button>

            <p>{{ 'toolbar.options.logOut'  | translate }}</p>
        </li>
    </ul>
</div>