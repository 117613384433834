<div class="invalidate-confirmation d-flex flex-column justify-content-center align-items-center p-3">
    <form [formGroup]="notificationForm" (submit)="respondToReport(notificationForm)" class="w-100">

        <div class="d-flex flex-column" *ngIf="type !== 'SIMPLE_TEXT'">
            <mat-form-field class="w-100">
                <input matInput type="text" formControlName="subject"
                  [placeholder]="'others.generalTranslations.subject' | translate">
                <button mat-icon-button matSuffix type="button">
                  <mat-icon>sms</mat-icon>
                </button>
            </mat-form-field>
    
    
            <mat-form-field class="mt-2 w-100">
                <mat-select formControlName="importance" [placeholder]="'admin.importance' | translate">
                  <mat-option value="HIGH">{{ 'admin.high' | translate }}</mat-option>
                  <mat-option value="MID">{{ 'admin.medium' | translate }}</mat-option>
                  <mat-option value="LOW">{{ 'admin.low' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>

        <div *ngIf="type === 'SIMPLE_TEXT'">
            <small>{{help}}</small>
        </div>


        <div class="d-flex flex-column text-area-container w-100 mt-3">
            <textarea class="lead text-area-editable w-100" [maxlength]="maxDescriptionLength" (keyup)="updateCounter($event)"
                [attr.placeHolder]="'admin.writteAMessage' |
                translate" id="user-description"
                #descriptionElement formControlName="description"></textarea>
    
            <small><span>{{ textLength }}</span> / {{ maxDescriptionLength }}</small>
        </div>
    
        <!-- controls section -->
        <div class="controls">
            <div class="d-flex flex-wrap justify-content-center align-items-center action-buttons">
                <button class="mfs-control-button" type="button" (click)="closeDialog({})">{{'others.generalTranslations.cancel' | translate}}</button>
                <button class="mfs-control-button">{{'others.generalTranslations.accept' | translate}}</button>
            </div>
        </div>
    </form>

</div>