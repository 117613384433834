// profile card component ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---


// Imports =====================================================================================================
import { Component, OnInit } from '@angular/core';


// Interfaces/models ===========================================================================================
import { IUser } from '@core/models/IUser';

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrl: './user-profile-card.component.css'
})
export class UserProfileCardComponent implements OnInit{

  //General Properties ========================================================================================
  public defaultUserProfileImg:string='assets/svgs/miner-svgrepo-com.svg';

  public userData:IUser={
    name:'Christian Sierra',
    email:'test@hotmail.com',
    dateOfBirth:'05/08/1998',
    imgUrl:'assets/generalImages/fotoMia.jpeg',
    gender:'Masculino',
    phoneNumber:'302322554',
    phoneCode:'+57',
    documentType:'CC',
    documentId:'1151322543',
    status:'Contratado',
    address:'cll31c #99-c-AA',
    city:'Tolima',
    country:'Colombia'
  }
  constructor(){}
  // LifeCycles ================================================================================================
  ngOnInit(): void {}

  // CustomFuntions ============================================================================================
}
