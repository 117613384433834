import { NgModule } from '@angular/core';
import { ToolsBarComponent } from '@shared/components/tools-bar/tools-bar.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { MaterialModule } from './material.module';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';
import { FamilyCardComponent } from './components/family-card/family-card.component';
import { SupporTypeCardComponent } from './components/suppor-type-card/suppor-type-card.component';
import { HttpClientModule} from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';;
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatError } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { DateFromNowPipe } from './pipes/date-from-now.pipe';
import {MatSelectModule} from '@angular/material/select';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CommonModule} from '@angular/common';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SmallTextWriteModalComponent } from './components/small-text-write-modal/small-text-write-modal.component';
import { NgxEditorModule } from 'ngx-editor';
import { SafePipe } from './pipes/safe.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [
    ToolsBarComponent,
    HeaderComponent,
    CarrouselComponent,
    MultiStepFormComponent,
    UserProfileCardComponent,
    FamilyCardComponent,
    SupporTypeCardComponent,
    DateFromNowPipe,
    NotFoundComponent,
    ConfirmModalComponent,
    SmallTextWriteModalComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    NgxEditorModule,
    HttpClientModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    CarouselModule,
    NgxIntlTelInputModule,
    MatDatepickerModule,
    MatNativeDateModule ,
    MatFormFieldModule,
    MatMenuModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatFormField,
    MatLabel,
    MatError,
    MatHint,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  exports:[
    ToolsBarComponent,
    NgxDocViewerModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    CarrouselComponent,
    MultiStepFormComponent,
    UserProfileCardComponent,
    FamilyCardComponent,
    SupporTypeCardComponent,
    NgxEditorModule,
    TranslateModule,
    MatMenuModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatFormField,
    MatLabel,
    MatError,
    MatDatepickerModule,
    MatHint,
    MatInputModule,
    DateFromNowPipe,
    MatSelectModule,
    NotFoundComponent,
    MatCheckboxModule,
    ConfirmModalComponent,
    SmallTextWriteModalComponent,
    SafePipe
  ],
  providers: [  
    MatDatepickerModule  
  ]
})
export class SharedModule { }
