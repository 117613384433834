import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-small-text-write-modal',
  templateUrl: './small-text-write-modal.component.html',
  styleUrl: './small-text-write-modal.component.css'
})
export class SmallTextWriteModalComponent {
  @ViewChild('descriptionElement') descriptionElement!:ElementRef;
  public notificationForm:FormGroup=new FormGroup({});
  public textLength:number=0;
  public maxDescriptionLength:number=500;
  public type:string='';
  public help:string='';

  constructor(public dialogRef: MatDialogRef<SmallTextWriteModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.maxDescriptionLength=this.data.maxTextLenght;
    this.type=this.data.type;
    this.help=this.data.help;

    
    this.notificationForm = new FormGroup({
      description: new FormControl(''),
      subject:new FormControl(''),
      importance: new FormControl('')

    });


  }

  updateCounter(event: any) {
    this.textLength = event.target.value.length;
    this.descriptionElement.nativeElement.style.height = 'auto';
    let scheight=event.target.scrollHeight;
    this.descriptionElement.nativeElement.style.height = scheight + 'px';
  }

  closeDialog(data:any): void {
    this.dialogRef.close(data);
  }

  respondToReport(descriptionForm:any){

    let {description, subject, importance} = descriptionForm.value;
    this.dialogRef.close({confirm:true, content:description, subject:subject, importance:importance});
  }
}
