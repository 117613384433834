<!-- Carrousel  html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->

<div class="dsc-carousel" [ngStyle]="{'min-height':finalHeight}">

    <!-- Carrousel controls -->
    <div class="dsc-carousel_btns">
        <mat-icon class="dcs-carousel_icon" (click)="setBack()">arrow_circle_left</mat-icon>
        <mat-icon class="dcs-carousel_icon" (click)="setNext()">arrow_circle_right</mat-icon>
    </div>

    <!-- Carrousel content -->
    <div class="dsc-carousel_content">
        <ng-container *ngFor="let section of items">
            <div loading="lazy" class="dsc-carousel_content-item" [ngStyle]="{'background-image': 'url(' + section.image + ')','margin-left': section.marginLeft + '%' }">
                <h1>{{section.title?.first}}<span>{{section.title?.second}}</span></h1>
                <p>{{section.subtitle}}</p>
            </div>
        </ng-container>
    </div>

    <!-- Carrousel steps -->
    <div class="dsc-carousel_steps">
        <div class="dsc-carousel_steps-step dsc-carousel_steps-step-{{currentPosition === item.id ? 'active':''}}" *ngFor="let item of items" (click)="setCurrentPosition(item.id)">

        </div>
    </div>
</div>
