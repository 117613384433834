<!-- Carrousel  html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->

<nav data-bs-theme="dark"
    [ngClass]="{'header-container': transparentHeader, 'header-glass-panel': transparentHeader, 'header-container-transparent': !transparentHeader, 'header-glass-panel-transparent': !transparentHeader}">
    <!-- Section first of the header (left hand side)-->
    <div class="header-section-a">
        <div class="header-image-brand-container" *ngIf="transparentHeader">
            <img src="{{esmeraldasSantaRosaCropedIcon}}" alt="EsmeraldasSantaRosaLogo">
        </div>

        <div class="header-title">
            <h2>Esmeraldas Santa Rosa</h2>
        </div>

        <div class="header-short-title">
            <h2><span class="header-special-text">E</span>SR</h2>
        </div>
    </div>



    <!-- Section second of the header (right hand side)-->
    <div class="header-section-b">
        <select *ngIf="hideLangButton" class="form-select language-select" aria-label="Choose Language" (change)="selectLang($event)">
            <option [selected]="lang==='ES'" value="ES" selected>ES</option>
            <option [selected]="lang==='EN'" value="EN">EN</option>
        </select>

        <button *ngIf="!isLangingPage()" class="btn main-button" (click)="navToLanding()">{{'navBar.landing' | translate }}</button>
        
        <button *ngIf="authenticated() && !alreadyInHome()" class="btn main-button home-button" (click)="navToHome()"><fa-icon class="fa-icon-medium" [icon]="homeIcon"></fa-icon></button>
        <button *ngIf="!authenticated() && isLangingPage()" class="btn main-button" (click)="navToLogin()">{{'landing.banner.buttons.login' | translate }}</button>
        <button class="btn main-button fw-bold" (click)="logOut()" *ngIf="authenticated() && !alreadyInHome()">{{'landing.banner.buttons.logOut' | translate }}</button>
    </div>
</nav>