// Support ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

// Imports =====================================================================================================
import { Component,Input, OnInit } from '@angular/core';

// Interfaces/models ===========================================================================================
import { ISupportType } from '@core/models/ISupportType';

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-suppor-type-card',
  templateUrl: './suppor-type-card.component.html',
  styleUrl: './suppor-type-card.component.css'
})
export class SupporTypeCardComponent implements OnInit {
  //Input Properties ========================================================================================
  @Input() supportType!:ISupportType;

  //General Properties ========================================================================================
  //---

  constructor(){}

  ngOnInit(): void {}

  // CustomFuntions ============================================================================================
  //---
}
