<!-- profile card html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->

<div class="profile-card-container">

    <!-- Image section -->
    <div class="profile-user-image-container" [ngStyle]="{'background-image': 'url(' + userData.imgUrl + ')'}">
        <mat-icon class="profile-image-edit">photo_camera</mat-icon>
    </div>

    <!-- Name section -->
    <div class="profile-user-name-container">
        <h2>{{userData.name}}</h2>
    </div>
    

    <!-- Info section -->
    <div class="profile-user-card-info-container">

        <div class="profile-info-container">
            <div class="profile-label-container">
                <label for="UserName">Email</label>

            </div>
            <div class="profile-input-container">
                <input type="text" readonly value="{{userData.email}}">
                <mat-icon class="profile-info-user-edit">edit</mat-icon>
            </div>

        </div>

        <div class="profile-info-container">
            <div class="profile-label-container">
                <label for="UserName">PhoneNumber</label>

            </div>
            <div class="profile-input-container">
                <input type="text" readonly value="{{userData.phoneNumber}}">
                <mat-icon class="profile-info-user-edit">edit</mat-icon>
            </div>

        </div>

        <div class="profile-info-container">
            <div class="profile-label-container">
                <label for="UserName">Documento</label>

            </div>

            <div class="profile-input-container">
                <input type="text" readonly value="{{userData.documentId}}">
                <mat-icon class="profile-info-user-edit">edit</mat-icon>
            </div>

        </div>

        <div class="profile-info-container">
            <div class="profile-label-container">
                <label for="UserName">Estado</label>
            </div>

            <div class="profile-input-container">
                <input type="text" readonly value="{{userData.status}}">
                <mat-icon class="profile-info-user-edit">edit</mat-icon>
            </div>

        </div>
    </div>

    
    <div class="profile-more-info-container">

        <mat-icon class="profile-more-info-edit">edit</mat-icon>

        <div class="profile-more-info">
            <p>Fecha de nacimiento:</p>
            <p>{{userData.dateOfBirth}}</p>
        </div>

        <div class="profile-more-info">
            <p>Sexo:</p>
            <p>{{userData.gender}}</p>
        </div>
    </div>

    <div class="profile-more-info-container">

        <mat-icon class="profile-more-info-edit">edit</mat-icon>

        <div class="profile-more-info">
            <p>Direccion</p>
            <p>{{userData.address}}</p>
        </div>

        <div class="profile-more-info">
            <p>Pais</p>
            <p>{{userData.country}}</p>
        </div>
    </div>

    <div class="profile-more-info-container">

        <mat-icon class="profile-more-info-edit">edit</mat-icon>

        <div class="profile-more-info">
            <p>Fecha de nacimiento:</p>
            <p>{{userData.dateOfBirth}}</p>
        </div>

        <div class="profile-more-info">
            <p>Sexo:</p>
            <p>{{userData.gender}}</p>
        </div>
    </div>
</div>
