import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http:HttpClient, private cookieService:CookieService) { }

  download(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  downloadFromWeb(signedUrl:string){
    const headers = new HttpHeaders({ "skipInterceptor": "true" });
    return this.http.get(signedUrl, { responseType: 'blob', headers:headers });
  }

  getEmailFromIdToken(idToken:string){
      try{
        const decodedToekn:any = jwtDecode(idToken);
        const email:any = decodedToekn.email;
        return email;
      }catch(error){
        return null;
      }
  }

  getExpirationFromToken(accessToken: string):Observable<any>{
    try {
      const decodedToken: any = jwtDecode(accessToken);
      const exp: number = decodedToken.exp * 1000;
      const now = Date.now();
      
      const timeRemaining = exp - now;
  
      if (timeRemaining <= 0) return of("00:00:00");
  
      // Convert the difference to HH:MM:SS format
      const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
      const seconds = Math.floor((timeRemaining / 1000) % 60);
  
      return of(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
    } catch (error) {
      return of(null);
    }
  }
  

  checkPermissionAllowed(requiredPermissions:any=null, onlyRole:boolean=false, requiredRoles:any=null,debug:any=null,enforceTerms:any=true, onlyDeveloper:any=false,onlyAdmin:any=false){
    try{
      if(debug){
        console.log(requiredPermissions,onlyRole,requiredRoles,enforceTerms)
      }

      if(enforceTerms && (this.cookieService.get('terms') !== 'accepted' || this.cookieService.get('habbeas') !== 'accepted')){
        return false;
      }

      if(onlyDeveloper && 'developer' === JSON.parse(this.cookieService.get('user_role'))){
        return true
      }

      if(onlyAdmin && 'admin' === JSON.parse(this.cookieService.get('user_role'))){
        return true
      }

      if(onlyAdmin && 'admin' !== JSON.parse(this.cookieService.get('user_role'))){
        return false
      }

      if(onlyDeveloper && 'developer' !== JSON.parse(this.cookieService.get('user_role'))){
        return false
      }


      let userPermissions = JSON.parse(this.cookieService.get('user_permissions'));
      if(userPermissions.includes('full_permission') || userPermissions.includes('full_dev_permission') ){
        return true;
      };
  
  
      if(requiredPermissions && requiredPermissions.length > 0){
        for(let permission of requiredPermissions){
          if(!userPermissions.includes(permission)){
            return false;
          }
          return true;
        }
      }
  
  
      if(onlyRole){
        for(let role of requiredRoles){
          if(debug){
            console.log(JSON.parse(this.cookieService.get('user_role')))
            console.log(role)
            console.log(role === JSON.parse(this.cookieService.get('user_role')))
          }
          if(role === JSON.parse(this.cookieService.get('user_role'))){
            return true;
          }
        }
      };

      if(onlyRole && requiredRoles.length === 0){
        return true
      }
  
      return false;
    }catch(error){
      return false;
    }
  }

}
