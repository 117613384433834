<!-- Family card html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->


<div class="family-card-container" [ngClass]="cardColor">
    <div class="family-card-content">
        <div class="family-card-unit-info">
            <h3>{{familyMember.name}}</h3>
            <mat-icon>edit</mat-icon>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Parentezco: </p>
            <p class="family-card-data-value">{{familyMember.relation}}</p>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Contacto: </p>
            <p class="family-card-data-value">{{familyMember.cellphone}}</p>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Codigo pais: </p>
            <p class="family-card-data-value">{{familyMember.cellphoneCode}}</p>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Parentezco: </p>
            <p class="family-card-data-value">{{familyMember.relation}}</p>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Contacto: </p>
            <p class="family-card-data-value">{{familyMember.cellphone}}</p>
        </div>
        <div class="family-card-unit-info">
            <p class="family-card-data-label">Codigo pais: </p>
            <p class="family-card-data-value">{{familyMember.cellphoneCode}}</p>
        </div>
    </div>
</div>
