// Carrousel component ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

// Imports =====================================================================================================
import { Component, OnInit, Input } from '@angular/core';

// Interfaces/models ===========================================================================================
import { ICarouselItem } from '../../../core/models/Icarousel-item.metadata';


// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrl: './carrousel.component.css'
})
export class CarrouselComponent implements OnInit{
  //Input Properties ========================================================================================
  @Input() height:string = '500px';
  @Input() isFullScreen:boolean = false;
  @Input() items:ICarouselItem[]=[];

  //General Properties ========================================================================================
  public finalHeight:string|number = 0;
  public currentPosition:number=0;

  constructor(){this.finalHeight = this.isFullScreen ? '100%' : `${this.height}`}

  // LifeCycles ================================================================================================
  ngOnInit(): void {
      this.items.map((item,idx)=>{
        item.id = idx;
        item.marginLeft = 0;
      })
  }

  // CustomFuntions ============================================================================================
  setCurrentPosition(position:number):void{
    this.currentPosition = position;
    this.items.find(i=>i.id === 0)!.marginLeft = -100 * position;
  }

  setNext():void{
    let finalPercentage = 0;
    let nextPosition = this.currentPosition + 1;

    if (nextPosition <= this.items.length - 1 ){
      finalPercentage = -100 * nextPosition;
    }else{
      nextPosition = 0;
    }

    let result = this.items.find(i => i.id === 0)
    result!.marginLeft = finalPercentage;

    this.currentPosition = nextPosition;
  }

  setBack():void{
    let finalPercentage = 0;
    let previousPosition = this.currentPosition - 1;

    if (previousPosition >= 0 ){
      finalPercentage = -100 * previousPosition;
    }else{
      previousPosition = this.items.length - 1;
      finalPercentage = -100 * previousPosition;
    }

    let result = this.items.find(i => i.id === 0);
    result!.marginLeft = finalPercentage;
    this.currentPosition = previousPosition;
  }
}
