import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';

@Pipe({
  name: 'dateFromNow'
})
export class DateFromNowPipe implements PipeTransform {

  transform(value: string, locale:string = 'en'): string {
    const dateLocale = locale === 'es' ? es : undefined;
    return formatDistanceToNow(new Date(value), {  locale:dateLocale });
  }

}
