<!-- multi step form html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->

<div class="msf-container">

    <!-- Has a step progress widget to display the step of the form -->
    <div class="msf-progress-container" *ngIf="!userCreated && firstMessageContinue">
        <div class="mfs-steps-container">
            <div class="mfs-progress-number" [ngClass]="currentStep >= 1 ? 'mfs-step-active':''">1</div>
            <div class="mfs-progress-line" [ngClass]="currentStep >= 2 ? 'mfs-step-completed':''"></div>
            <div class="mfs-progress-number" [ngClass]="currentStep >= 2 ? 'mfs-step-active':''">2</div>
            <div class="mfs-progress-line" [ngClass]="currentStep >= 3 ? 'mfs-step-completed': ''"></div>
            <div class="mfs-progress-number" [ngClass]="currentStep >= 3 ? 'mfs-step-active':''">3</div>
            <div class="mfs-progress-line" [ngClass]="currentStep >= 4 ? 'mfs-step-completed': ''"></div>
            <div class="mfs-progress-number" [ngClass]="currentStep >= 4 ? 'mfs-step-active':''">4</div>
        </div>
    </div>

    <!-- It contains the form cards and the real data-->
    <form [formGroup]="registerFormGroup" class="multi-step-form-container" *ngIf="!userCreated" data-aos="flip-left" data-aos-duration="500" [ngClass]="{'bg-transparent': currentStep == 4 || currentStep == 3, 'glass-bg':firstMessageContinue }">
        <!-- Basic user data card form -->
        <div class="mfs-custom-card first-card" *ngIf="currentStep == 1"
            [ngClass]="currentStep == 1 ? 'mfs-custom-active' : 'mfs-custom-hidden'" data-aos="flip-left"
            data-aos-duration="500">
            
            <div class="explanation-message" *ngIf="!firstMessageContinue && preferredLang === 'es'">
                <p>El proceso de registro como Usuario en la plataforma requiere de la entrega de tu información personal y de tu autorización para el tratamiento de tus datos personales. Por tanto, respecto del uso de tu información personal, ESMERALDAS SANTA ROSA S.A. (identificada con NIT. Nº 800.231.848-1, con dirección física en la carrera 9 # 113-52, oficina 1504B de la ciudad de Bogotá, con teléfono +57 601 7445306, quien en adelante se denominará ESR o la organización), te informa que:</p>
                <ol>
                    <li>Tu información será tratada de acuerdo con la Política de Tratamiento de Datos Personales de ESR, la cual puede ser consultada <a href="#">aquí</a>.</li>
                    <li>Todo titular tiene derecho a: conocer, actualizar y rectificar su información personal; acceder de manera gratuita a la misma; ser informado sobre su uso; solicitar prueba de la autorización; acudir ante la Superintendencia de Industria y Comercio y presentar quejas por infracciones a lo dispuesto en la normatividad vigente; y en los casos procedentes, modificar y revocar la autorización y/o solicitar la supresión de sus datos personales.</li>
                    <li>Es de carácter libre y facultativo entregar datos o responder a preguntas que versen sobre datos de carácter sensibles (Como lo son, los datos biométricos, relacionados con la salud, información sobre raza u origen, creencias religiosas, sexualidad, o aquellos que afectan su intimidad o cuyo uso indebido pueda generar discriminación), y usted no está obligado a otorgar su autorización.</li>
                    <li>Para cualquier inquietud o información relacionada con el tratamiento de sus datos personales y el ejercicio de su derecho de hábeas data, puede contactarse por escrito al canal de atención electrónico <a href="mailto:protecciondedatos@esmeraldassantarosa.com">{{'protecciondedatos@esmeraldassantarosa.com'}}</a>.</li>
                </ol>

                <button type="button" (click)="firstMessageContinue = true;">Continuar</button>
            </div>

            <div class="explanation-message" *ngIf="!firstMessageContinue && preferredLang === 'en'">
                <p>The process of registering as a User on the platform requires the provision of your personal information and your authorization for the processing of your personal data. Therefore, regarding the use of your personal information, ESMERALDAS SANTA ROSA S.A. (identified with NIT. No. 800.231.848-1, with a physical address at Carrera 9 # 113-52, office 1504B in the city of Bogotá, with telephone +57 601 7445306, hereinafter referred to as ESR or the organization), informs you that:</p>
                <ol>
                    <li>Your information will be treated in accordance with ESR's Personal Data Processing Policy, which can be consulted <a href="#">here</a>.</li>
                    <li>Every data subject has the right to: know, update, and rectify their personal information; access it free of charge; be informed about its use; request proof of authorization; approach the Superintendence of Industry and Commerce and file complaints for violations of the current regulations; and in applicable cases, modify and revoke the authorization and/or request the deletion of their personal data.</li>
                    <li>It is optional to provide data or answer questions regarding sensitive data (such as biometric data, health-related information, information on race or origin, religious beliefs, sexuality, or those affecting your privacy or whose misuse could lead to discrimination), and you are not obliged to give your authorization.</li>
                    <li>For any questions or information related to the processing of your personal data and the exercise of your right to habeas data, you can contact the electronic customer service channel at <a href="mailto:protecciondedatos@esmeraldassantarosa.com">{{'protecciondedatos@esmeraldassantarosa.com'}}</a>.</li>
                </ol>

                <button type="button" (click)="firstMessageContinue = true;">Continuar</button>
            </div>
            
            
            <ng-container *ngIf="firstMessageContinue">
                <div>
                    <div class="mfs-form-card-title">
                        <h2>{{'register.stepA.title' | translate}}</h2>
                    </div>
                </div>
    
    
                <div class="mfs-input-div">
                    <div class="d-flex flex-column label-input-container">
                        <label for="email">{{'register.stepA.emailInput.label' | translate}}</label>
                        <div class="inner-input-container">
                            <input formControlName="email" type="email"
                                [placeholder]="'register.stepA.emailInput.placeholder' | translate" required>
                            <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="emailIcon"></fa-icon></span>
                        </div>
                    </div>
    
    
                </div>
    
                <div class="mfs-input-div">
                    <div class="d-flex flex-column label-input-container">
                        <label for="userName">{{'register.stepA.userInput.label' | translate}}</label>
                        <div class="inner-input-container">
                            <input formControlName="userName" type="text"
                                [placeholder]="'register.stepA.userInput.placeholder' | translate">
                            <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="userIcon"></fa-icon></span>
                        </div>
                    </div>
    
    
                </div>
    
                <div class="mfs-input-div">
                    <div class="d-flex flex-column label-input-container">
                        <label for="dni">{{'register.stepA.id.label' | translate}}</label>
                        <div class="inner-input-container">
                            <input formControlName="dni" type="text"
                                [placeholder]="'register.stepA.id.placeholder' | translate">
                            <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="idIcon"></fa-icon></span>
                        </div>
                    </div>
    
                </div>
    
                <div class="mfs-input-div-select">
                    <label for="dni_type">{{'register.stepA.selectInput.label' | translate}}</label>
                    <div class="mfs-select-dni-container">
                        <select class="mfs-select-dni form-select" formControlName="dni_type" name="dni_type">
                            <option value="CC">{{'register.stepA.selectInput.options.optionA' | translate}}</option>
                            <option value="TI">{{'register.stepA.selectInput.options.optionB' | translate}}</option>
                            <option value="CE">{{'register.stepA.selectInput.options.optionC' | translate}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>

        </div>

        <!-- Family user data card form -->
        <div class="mfs-custom-card" *ngIf="currentStep == 2"
            [ngClass]="currentStep == 2 ? 'mfs-custom-active' : 'mfs-custom-hidden'" data-aos="flip-right"
            data-aos-duration="500">

            <div>
                <div class="mfs-form-card-title">
                    <h2>{{ 'register.stepB.title' | translate }}</h2>
                </div>
            </div>


            <p class="text-center ps-2 pe-2">
                {{'legal.familyWarning' | translate}}
            </p>


            <div *ngIf="familyInfoControls.length >= 1" formArrayName="familyInfo" class="carousel-container">
                <owl-carousel-o [options]="customOptions" class="test">
                    <ng-container *ngFor="let familyMember of familyInfoControls  let i = index" [formGroupName]="i">
                        <ng-template carouselSlide>
                            <div class="mfs-full-family-container">
                                <!-- remove member button -->
                                <div class="mfs-remove-button-container">
                                    <button class="mfs-remove-family-button"
                                        (click)="removeFamilyMember(i)"><fa-icon class="fa-icon-medium"
                                        [icon]="minusIcon"></fa-icon></button>
                                </div>

                                <!-- form payload -->
                                <div class="family-member-container">
                                    <div class="mfs-input-div">
                                        <div class="d-flex flex-column label-input-container">
                                            <label for="familyMemberName">{{'register.stepB.name' |
                                                translate }}</label>
                                            <div class="inner-input-container">
                                                <input [class]="getClass(i)" type="text" [placeholder]="'register.stepB.name' |
                                                translate"
                                                    formControlName="name">
                                                <span class="symbol-input100"><fa-icon class="fa-icon-medium"
                                                        [icon]="userIcon"></fa-icon></span>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="mfs-input-div">
                                        <div class="d-flex flex-column label-input-container">
                                            <label for="familyMemberEmail">{{'register.stepB.email' | translate }}</label>
                                            <div class="inner-input-container">
                                                <input [class]="getClass(i)" type="email" [placeholder]="'register.stepB.email' |
                                                translate"
                                                    formControlName="email">
                                                <span class="symbol-input100"><fa-icon class="fa-icon-medium"
                                                        [icon]="emailIcon"></fa-icon></span>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="mfs-input-div-select">

                                        <div class="mfs-select-dni-container">
                                            <label for="familyMemberRelation">{{'register.stepB.relation' |
                                                translate }}</label>
                                            <select class="mfs-select-dni form-select" formControlName="relation"
                                                name="relation">
                                                <option value="father">{{'register.stepB.options.father' | translate }}</option>
                                                <option value="son">{{'register.stepB.options.son' | translate }}</option>
                                                <option value="sibling">{{'register.stepB.options.sibling' | translate }}</option>
                                                <option value="relation">{{'register.stepB.options.relation' | translate }}</option>
                                                <option value="married">{{'register.stepB.options.married' | translate }}</option>
                                                <option value="other">{{'register.stepB.options.other' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>

            <div class="mfs-add-button-container container" *ngIf="familyInfoControls.length < 5">
                <button class="mfs-control-button mt-2" (click)="addFamilyMember()">{{'register.stepB.addMember' |
                    translate }}</button>
            </div>
        </div>

        <!-- Additional user data card form -->
        <div class="mfs-custom-card last-card" *ngIf="currentStep == 3" [ngClass]="currentStep == 3 ? 'mfs-custom-active' : 'mfs-custom-hidden'" data-aos="flip-left" data-aos-duration="500" >
            <!-- Title and description -->
            <div class="mb-2">
                <div class="mfs-form-card-title mb-3">
                    <h2>{{'register.stepC.title' | translate}}</h2>
                </div>

                <!-- <div class="mfs-from-card-description text-center">
                    <p>{{'register.stepC.description' | translate}}</p>
                </div> -->
            </div>

            <!-- Full container form -->
            <div class="additional-data-container">

                <!-- Name data -->
                <div class="mfs-input-div">
                    <div class="d-flex flex-column last-card-label-input-container">
                        <label>{{'register.stepC.fullName' | translate}}</label>
                        <div class="double-input-container mb-2">
                            <div class="inner-input-container">
                                <input type="text" formControlName="firstName" [placeholder]="'register.stepC.firstName' | translate">
                                <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="userIcon"></fa-icon></span>
                            </div>
        
                            <div class="inner-input-container">
                                <input type="text" formControlName="secondName" [placeholder]="'register.stepC.secondName' | translate">
                                <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="userIcon"></fa-icon></span>
                            </div>
                        </div>

                        <div class="double-input-container">
                            <div class="inner-input-container">
                                <input type="text" formControlName="firstSurname" [placeholder]="'register.stepC.firstSurname' | translate">
                                <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="userIcon"></fa-icon></span>
                            </div>
        
                            <div class="inner-input-container">
                                <input type="text" formControlName="secondSurname" [placeholder]="'register.stepC.secondSurname' | translate">
                                <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="userIcon"></fa-icon></span>
                            </div>
                        </div>
                    </div>

                </div>



                <!-- Gender -->
                <div class="mfs-input-div">
                    <div class="inner-input-container">
                        <div class="double-input-container mb-2">

                            <div class="inner-input-container last-card-label-input-container">
                                <div class="d-flex flex-column">
                                    <label>{{'register.stepC.gender.value' | translate}}</label>
                                    <div>
                                        <select class="form-select" formControlName="gender">
                                            <option value="" disabled="">{{'register.stepC.gender.value' | translate}}</option>
                                            <option value="other">{{'register.stepC.gender.options.c' | translate}}</option>
                                            <option value="male">{{'register.stepC.gender.options.a' | translate}}</option>
                                            <option value="female">{{'register.stepC.gender.options.b' | translate}}</option>
                                        </select>  
                                    </div>
                                </div>

                            </div>
    
                            <div class="inner-input-container last-card-label-input-container">
                                <div class="d-flex flex-column">
                                    <label>{{'register.stepC.birth' | translate}}</label>
                                    <div class="inner-input-container">
                                        <input matInput [matDatepicker]="picker" formControlName="birthDay" placeholder="Choose a date" (click)="picker.open()">
                                        <span class="symbol-input100" (click)="picker.open()"><fa-icon class="fa-icon-medium" [icon]="calendarIcon"></fa-icon></span>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <hr>

                <!-- Addres data -->
                <div class="mfs-input-div mt-3">
                    <div class="d-flex flex-column last-card-label-input-container">
                        <label>{{'register.stepC.address' | translate}}</label>
                        <div class="inner-input-container">
                            <div class="address-inputs mb-2 inner-input-container">
                                <input formControlName="address" type="text" [placeholder]="'register.stepC.addressLine' | translate">
                                <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="addressIcon"></fa-icon></span>
                            </div>
    
                            <div class="double-input-container mb-2 justify-content-between">
                                <div class="inner-input-container">
                                    <input formControlName="city" type="text" [placeholder]="'register.stepC.city' | translate">
                                    <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="cityIcon"></fa-icon></span>
                                </div>
                                
                                <div class="inner-input-container">
                                    <input formControlName="department" type="text" [placeholder]="'register.stepC.region' | translate">
                                    <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="regionIcon"></fa-icon></span>
                                </div>
                                
                            </div>
                            
                            <div class="double-input-container justify-content-between">
                                <!-- <div class="inner-input-container">
                                    <input formControlName="postal" type="text" [placeholder]="'register.stepC.postalCode' | translate">
                                    <span class="symbol-input100"><fa-icon class="fa-icon-medium" [icon]="postalCodeIcon"></fa-icon></span>
                                </div> -->
                                
    
                                <div class="w-100">
                                    <select class="form-select" formControlName="country">
                                        <option value="" disabled="">{{'register.stepC.country' | translate}}</option>
                                        <option value="Afghanistan" data-country-code="AF">Afghanistan</option>
                                        <option value="Åland Islands" data-country-code="AX">Åland Islands</option>
                                        <option value="Albania" data-country-code="AL">Albania</option>
                                        <option value="Algeria" data-country-code="DZ">Algeria</option>
                                        <option value="American Samoa" data-country-code="AS">American Samoa</option>
                                        <option value="Andorra" data-country-code="AD">Andorra</option>
                                        <option value="Angola" data-country-code="AO">Angola</option>
                                        <option value="Anguilla" data-country-code="AI">Anguilla</option>
                                        <option value="Antarctica" data-country-code="AQ">Antarctica</option>
                                        <option value="Antigua and Barbuda" data-country-code="AG">Antigua andBarbuda</option>
                                        <option value="Argentina" data-country-code="AR">Argentina</option>
                                        <option value="Armenia" data-country-code="AM">Armenia</option>
                                        <option value="Aruba" data-country-code="AW">Aruba</option>
                                        <option value="Australia" data-country-code="AU">Australia</option>
                                        <option value="Austria" data-country-code="AT">Austria</option>
                                        <option value="Azerbaijan" data-country-code="AZ">Azerbaijan</option>
                                        <option value="Bahamas" data-country-code="BS">Bahamas</option>
                                        <option value="Bahrain" data-country-code="BH">Bahrain</option>
                                        <option value="Bangladesh" data-country-code="BD">Bangladesh</option>
                                        <option value="Barbados" data-country-code="BB">Barbados</option>
                                        <option value="Belarus" data-country-code="BY">Belarus</option>
                                        <option value="Belgium" data-country-code="BE">Belgium</option>
                                        <option value="Belize" data-country-code="BZ">Belize</option>
                                        <option value="Benin" data-country-code="BJ">Benin</option>
                                        <option value="Bermuda" data-country-code="BM">Bermuda</option>
                                        <option value="Bhutan" data-country-code="BT">Bhutan</option>
                                        <option value="Bolivia" data-country-code="BO">Bolivia</option>
                                        <option value="Bosnia and Herzegovina" data-country-code="BA">Bosnia andHerzegovina</option>
                                        <option value="Botswana" data-country-code="BW">Botswana</option>
                                        <option value="Bouvet Island" data-country-code="BV">Bouvet Island</option>
                                        <option value="Brazil" data-country-code="BR">Brazil</option>
                                        <option value="British Indian Ocean Territory" data-country-code="IO">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam" data-country-code="BN">BruneiDarussalam</option>
                                        <option value="Bulgaria" data-country-code="BG">Bulgaria</option>
                                        <option value="Burkina Faso" data-country-code="BF">Burkina Faso</option>
                                        <option value="Burundi" data-country-code="BI">Burundi</option>
                                        <option value="Cambodia" data-country-code="KH">Cambodia</option>
                                        <option value="Cameroon" data-country-code="CM">Cameroon</option>
                                        <option value="Canada" data-country-code="CA">Canada</option>
                                        <option value="Cape Verde" data-country-code="CV">Cape Verde</option>
                                        <option value="Cayman Islands" data-country-code="KY">Cayman Islands</option>
                                        <option value="Central African Republic" data-country-code="CF">Central African Republic</option>
                                        <option value="Chad" data-country-code="TD">Chad</option>
                                        <option value="Chile" data-country-code="CL">Chile</option>
                                        <option value="China" data-country-code="CN">China</option>
                                        <option value="Christmas Island" data-country-code="CX">Christmas Island</option>
                                        <option value="Cocos (Keeling) Islands" data-country-code="CC">Cocos(Keeling) Islands</option>
                                        <option value="Colombia" data-country-code="CO" selected="">Colombia</option>
                                        <option value="Comoros" data-country-code="KM">Comoros</option>
                                        <option value="Congo" data-country-code="CG">Congo</option>
                                        <option value="Congo, The Democratic Republic of the" data-country-code="CD">Congo, The Democratic Republic of the</option>
                                        <option value="Cook Islands" data-country-code="CK">Cook Islands</option>
                                        <option value="Costa Rica" data-country-code="CR">Costa Rica</option>
                                        <option value="Cote D'Ivoire" data-country-code="CI">Cote D'Ivoire</option>
                                        <option value="Croatia" data-country-code="HR">Croatia</option>
                                        <option value="Cuba" data-country-code="CU">Cuba</option>
                                        <option value="Cyprus" data-country-code="CY">Cyprus</option>
                                        <option value="Czech Republic" data-country-code="CZ">Czech Republic</option>
                                        <option value="Denmark" data-country-code="DK">Denmark</option>
                                        <option value="Djibouti" data-country-code="DJ">Djibouti</option>
                                        <option value="Dominica" data-country-code="DM">Dominica</option>
                                        <option value="Dominican Republic" data-country-code="DO">Dominican Republic</option>
                                        <option value="Ecuador" data-country-code="EC">Ecuador</option>
                                        <option value="Egypt" data-country-code="EG">Egypt</option>
                                        <option value="El Salvador" data-country-code="SV">El Salvador</option>
                                        <option value="Equatorial Guinea" data-country-code="GQ">Equatorial Guinea</option>
                                        <option value="Eritrea" data-country-code="ER">Eritrea</option>
                                        <option value="Estonia" data-country-code="EE">Estonia</option>
                                        <option value="Ethiopia" data-country-code="ET">Ethiopia</option>
                                        <option value="Falkland Islands (Malvinas)" data-country-code="FK">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands" data-country-code="FO">Faroe Islands</option>
                                        <option value="Fiji" data-country-code="FJ">Fiji</option>
                                        <option value="Finland" data-country-code="FI">Finland</option>
                                        <option value="France" data-country-code="FR">France</option>
                                        <option value="French Guiana" data-country-code="GF">French Guiana</option>
                                        <option value="French Polynesia" data-country-code="PF">French Polynesia</option>
                                        <option value="French Southern Territories" data-country-code="TF">French Southern Territories</option>
                                        <option value="Gabon" data-country-code="GA">Gabon</option>
                                        <option value="Gambia" data-country-code="GM">Gambia</option>
                                        <option value="Georgia" data-country-code="GE">Georgia</option>
                                        <option value="Germany" data-country-code="DE">Germany</option>
                                        <option value="Ghana" data-country-code="GH">Ghana</option>
                                        <option value="Gibraltar" data-country-code="GI">Gibraltar</option>
                                        <option value="Greece" data-country-code="GR">Greece</option>
                                        <option value="Greenland" data-country-code="GL">Greenland</option>
                                        <option value="Grenada" data-country-code="GD">Grenada</option>
                                        <option value="Guadeloupe" data-country-code="GP">Guadeloupe</option>
                                        <option value="Guam" data-country-code="GU">Guam</option>
                                        <option value="Guatemala" data-country-code="GT">Guatemala</option>
                                        <option value="Guernsey" data-country-code="GG">Guernsey</option>
                                        <option value="Guinea" data-country-code="GN">Guinea</option>
                                        <option value="Guinea-Bissau" data-country-code="GW">Guinea-Bissau</option>
                                        <option value="Guyana" data-country-code="GY">Guyana</option>
                                        <option value="Haiti" data-country-code="HT">Haiti</option>
                                        <option value="Heard Island and Mcdonald Islands" data-country-code="HM">Heard Island and Mcdonald Islands</option>
                                        <option value="Holy See (Vatican City State)" data-country-code="VA">Holy See (Vatican City State)</option>
                                        <option value="Honduras" data-country-code="HN">Honduras</option>
                                        <option value="Hong Kong" data-country-code="HK">Hong Kong</option>
                                        <option value="Hungary" data-country-code="HU">Hungary</option>
                                        <option value="Iceland" data-country-code="IS">Iceland</option>
                                        <option value="India" data-country-code="IN">India</option>
                                        <option value="Indonesia" data-country-code="ID">Indonesia</option>
                                        <option value="Iran, Islamic Republic Of" data-country-code="IR">Iran,Islamic Republic Of</option>
                                        <option value="Iraq" data-country-code="IQ">Iraq</option>
                                        <option value="Ireland" data-country-code="IE">Ireland</option>
                                        <option value="Isle of Man" data-country-code="IM">Isle of Man</option>
                                        <option value="Israel" data-country-code="IL">Israel</option>
                                        <option value="Italy" data-country-code="IT">Italy</option>
                                        <option value="Jamaica" data-country-code="JM">Jamaica</option>
                                        <option value="Japan" data-country-code="JP">Japan</option>
                                        <option value="Jersey" data-country-code="JE">Jersey</option>
                                        <option value="Jordan" data-country-code="JO">Jordan</option>
                                        <option value="Kazakhstan" data-country-code="KZ">Kazakhstan</option>
                                        <option value="Kenya" data-country-code="KE">Kenya</option>
                                        <option value="Kiribati" data-country-code="KI">Kiribati</option>
                                        <option value="Korea, Democratic People'S Republic of" data-country-code="KP">Korea, Democratic People'S Republic of</option>
                                        <option value="Korea, Republic of" data-country-code="KR">Korea, Republic of</option>
                                        <option value="Kuwait" data-country-code="KW">Kuwait</option>
                                        <option value="Kyrgyzstan" data-country-code="KG">Kyrgyzstan</option>
                                        <option value="Lao People'S Democratic Republic" data-country-code="LA">Lao People'S Democratic Republic</option>
                                        <option value="Latvia" data-country-code="LV">Latvia</option>
                                        <option value="Lebanon" data-country-code="LB">Lebanon</option>
                                        <option value="Lesotho" data-country-code="LS">Lesotho</option>
                                        <option value="Liberia" data-country-code="LR">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya" data-country-code="LY">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein" data-country-code="LI">Liechtenstein</option>
                                        <option value="Lithuania" data-country-code="LT">Lithuania</option>
                                        <option value="Luxembourg" data-country-code="LU">Luxembourg</option>
                                        <option value="Macao" data-country-code="MO">Macao</option>
                                        <option value="Macedonia, The Former Yugoslav Republic of"data-country-code="MK">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar" data-country-code="MG">Madagascar</option>
                                        <option value="Malawi" data-country-code="MW">Malawi</option>
                                        <option value="Malaysia" data-country-code="MY">Malaysia</option>
                                        <option value="Maldives" data-country-code="MV">Maldives</option>
                                        <option value="Mali" data-country-code="ML">Mali</option>
                                        <option value="Malta" data-country-code="MT">Malta</option>
                                        <option value="Marshall Islands" data-country-code="MH">Marshall Islands
                                        </option>
                                        <option value="Martinique" data-country-code="MQ">Martinique</option>
                                        <option value="Mauritania" data-country-code="MR">Mauritania</option>
                                        <option value="Mauritius" data-country-code="MU">Mauritius</option>
                                        <option value="Mayotte" data-country-code="YT">Mayotte</option>
                                        <option value="Mexico" data-country-code="MX">Mexico</option>
                                        <option value="Micronesia, Federated States of" data-country-code="FM">
                                            Micronesia, Federated States of</option>
                                        <option value="Moldova, Republic of" data-country-code="MD">Moldova,
                                            Republic of</option>
                                        <option value="Monaco" data-country-code="MC">Monaco</option>
                                        <option value="Mongolia" data-country-code="MN">Mongolia</option>
                                        <option value="Montserrat" data-country-code="MS">Montserrat</option>
                                        <option value="Morocco" data-country-code="MA">Morocco</option>
                                        <option value="Mozambique" data-country-code="MZ">Mozambique</option>
                                        <option value="Myanmar" data-country-code="MM">Myanmar</option>
                                        <option value="Namibia" data-country-code="NA">Namibia</option>
                                        <option value="Nauru" data-country-code="NR">Nauru</option>
                                        <option value="Nepal" data-country-code="NP">Nepal</option>
                                        <option value="Netherlands" data-country-code="NL">Netherlands</option>
                                        <option value="Netherlands Antilles" data-country-code="AN">Netherlands
                                            Antilles</option>
                                        <option value="New Caledonia" data-country-code="NC">New Caledonia
                                        </option>
                                        <option value="New Zealand" data-country-code="NZ">New Zealand</option>
                                        <option value="Nicaragua" data-country-code="NI">Nicaragua</option>
                                        <option value="Niger" data-country-code="NE">Niger</option>
                                        <option value="Nigeria" data-country-code="NG">Nigeria</option>
                                        <option value="Niue" data-country-code="NU">Niue</option>
                                        <option value="Norfolk Island" data-country-code="NF">Norfolk Island
                                        </option>
                                        <option value="Northern Mariana Islands" data-country-code="MP">Northern
                                            Mariana Islands</option>
                                        <option value="Norway" data-country-code="NO">Norway</option>
                                        <option value="Oman" data-country-code="OM">Oman</option>
                                        <option value="Pakistan" data-country-code="PK">Pakistan</option>
                                        <option value="Palau" data-country-code="PW">Palau</option>
                                        <option value="Palestinian Territory, Occupied" data-country-code="PS">
                                            Palestinian Territory, Occupied</option>
                                        <option value="Panama" data-country-code="PA">Panama</option>
                                        <option value="Papua New Guinea" data-country-code="PG">Papua New Guinea
                                        </option>
                                        <option value="Paraguay" data-country-code="PY">Paraguay</option>
                                        <option value="Peru" data-country-code="PE">Peru</option>
                                        <option value="Philippines" data-country-code="PH">Philippines</option>
                                        <option value="Pitcairn" data-country-code="PN">Pitcairn</option>
                                        <option value="Poland" data-country-code="PL">Poland</option>
                                        <option value="Portugal" data-country-code="PT">Portugal</option>
                                        <option value="Puerto Rico" data-country-code="PR">Puerto Rico</option>
                                        <option value="Qatar" data-country-code="QA">Qatar</option>
                                        <option value="Reunion" data-country-code="RE">Reunion</option>
                                        <option value="Romania" data-country-code="RO">Romania</option>
                                        <option value="Russian Federation" data-country-code="RU">Russian
                                            Federation</option>
                                        <option value="RWANDA" data-country-code="RW">RWANDA</option>
                                        <option value="Saint Helena" data-country-code="SH">Saint Helena
                                        </option>
                                        <option value="Saint Kitts and Nevis" data-country-code="KN">Saint Kitts
                                            and Nevis</option>
                                        <option value="Saint Lucia" data-country-code="LC">Saint Lucia</option>
                                        <option value="Saint Pierre and Miquelon" data-country-code="PM">Saint
                                            Pierre and Miquelon</option>
                                        <option value="Saint Vincent and the Grenadines" data-country-code="VC">
                                            Saint Vincent and the Grenadines</option>
                                        <option value="Samoa" data-country-code="WS">Samoa</option>
                                        <option value="San Marino" data-country-code="SM">San Marino</option>
                                        <option value="Sao Tome and Principe" data-country-code="ST">Sao Tome
                                            and Principe</option>
                                        <option value="Saudi Arabia" data-country-code="SA">Saudi Arabia
                                        </option>
                                        <option value="Senegal" data-country-code="SN">Senegal</option>
                                        <option value="Serbia and Montenegro" data-country-code="CS">Serbia and
                                            Montenegro</option>
                                        <option value="Seychelles" data-country-code="SC">Seychelles</option>
                                        <option value="Sierra Leone" data-country-code="SL">Sierra Leone
                                        </option>
                                        <option value="Singapore" data-country-code="SG">Singapore</option>
                                        <option value="Slovakia" data-country-code="SK">Slovakia</option>
                                        <option value="Slovenia" data-country-code="SI">Slovenia</option>
                                        <option value="Solomon Islands" data-country-code="SB">Solomon Islands
                                        </option>
                                        <option value="Somalia" data-country-code="SO">Somalia</option>
                                        <option value="South Africa" data-country-code="ZA">South Africa
                                        </option>
                                        <option value="South Georgia and the South Sandwich Islands"
                                            data-country-code="GS">South Georgia and the South Sandwich Islands
                                        </option>
                                        <option value="Spain" data-country-code="ES">Spain</option>
                                        <option value="Sri Lanka" data-country-code="LK">Sri Lanka</option>
                                        <option value="Sudan" data-country-code="SD">Sudan</option>
                                        <option value="Suriname" data-country-code="SR">Suriname</option>
                                        <option value="Svalbard and Jan Mayen" data-country-code="SJ">Svalbard
                                            and Jan Mayen</option>
                                        <option value="Swaziland" data-country-code="SZ">Swaziland</option>
                                        <option value="Sweden" data-country-code="SE">Sweden</option>
                                        <option value="Switzerland" data-country-code="CH">Switzerland</option>
                                        <option value="Syrian Arab Republic" data-country-code="SY">Syrian Arab
                                            Republic</option>
                                        <option value="Taiwan, Province of China" data-country-code="TW">Taiwan,
                                            Province of China</option>
                                        <option value="Tajikistan" data-country-code="TJ">Tajikistan</option>
                                        <option value="Tanzania, United Republic of" data-country-code="TZ">
                                            Tanzania, United Republic of</option>
                                        <option value="Thailand" data-country-code="TH">Thailand</option>
                                        <option value="Timor-Leste" data-country-code="TL">Timor-Leste</option>
                                        <option value="Togo" data-country-code="TG">Togo</option>
                                        <option value="Tokelau" data-country-code="TK">Tokelau</option>
                                        <option value="Tonga" data-country-code="TO">Tonga</option>
                                        <option value="Trinidad and Tobago" data-country-code="TT">Trinidad and
                                            Tobago</option>
                                        <option value="Tunisia" data-country-code="TN">Tunisia</option>
                                        <option value="Turkey" data-country-code="TR">Turkey</option>
                                        <option value="Turkmenistan" data-country-code="TM">Turkmenistan
                                        </option>
                                        <option value="Turks and Caicos Islands" data-country-code="TC">Turks
                                            and Caicos Islands</option>
                                        <option value="Tuvalu" data-country-code="TV">Tuvalu</option>
                                        <option value="Uganda" data-country-code="UG">Uganda</option>
                                        <option value="Ukraine" data-country-code="UA">Ukraine</option>
                                        <option value="United Arab Emirates" data-country-code="AE">United Arab
                                            Emirates</option>
                                        <option value="United Kingdom" data-country-code="GB">United Kingdom
                                        </option>
                                        <option value="United States" data-country-code="US">United States
                                        </option>
                                        <option value="United States Minor Outlying Islands" data-country-code="UM">
                                            United States Minor Outlying Islands</option>
                                        <option value="Uruguay" data-country-code="UY">Uruguay</option>
                                        <option value="Uzbekistan" data-country-code="UZ">Uzbekistan</option>
                                        <option value="Vanuatu" data-country-code="VU">Vanuatu</option>
                                        <option value="Venezuela" data-country-code="VE">Venezuela</option>
                                        <option value="Viet Nam" data-country-code="VN">Viet Nam</option>
                                        <option value="Virgin Islands, British" data-country-code="VG">Virgin
                                            Islands, British</option>
                                        <option value="Virgin Islands, U.S." data-country-code="VI">Virgin
                                            Islands, U.S.</option>
                                        <option value="Wallis and Futuna" data-country-code="WF">Wallis and
                                            Futuna</option>
                                        <option value="Western Sahara" data-country-code="EH">Western Sahara
                                        </option>
                                        <option value="Yemen" data-country-code="YE">Yemen</option>
                                        <option value="Zambia" data-country-code="ZM">Zambia</option>
                                        <option value="Zimbabwe" data-country-code="ZW">Zimbabwe</option>
                                    </select>  
                                </div>
                            </div>
    
                            
                        </div>
                    </div>

                </div>

                <hr>

                <!-- Contact data -->
                <div class="mfs-input-div">
                    <div class="double-input-container d-flex justify-content-center">
                        <div>
                            <div class="d-flex flex-column last-card-label-input-container">
                                <label>{{ 'register.stepC.cellphone' | translate}}</label>
                                <div class="double-input-container">
                                    <ngx-intl-tel-input
                                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                        [enableAutoCountrySelect]="false"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.Colombia"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        name="cellphone"
                                        formControlName="cellphone"
                                        customPlaceholder="3105551234"
                                  ></ngx-intl-tel-input>
                                </div>
                            </div>
                        </div>
                
                    </div>

                </div>



                <!-- found us way -->
                <div class="mfs-input-div mb-3">
                    <div class="d-flex flex-column last-card-label-input-container">
                        <label data-wp="20">{{ 'register.stepC.foundUs' | translate}}</label>
                        <textarea spellcheck="false" placeholder="" formControlName="foundUs"></textarea>
                    </div>

                </div>

            </div>

            <!-- msf buttons controler -->
            <div class="mfs-controls-div mb-3" *ngIf="currentStep === 3">
                <button class="mfs-control-button"
                    (click)="previousStep()">{{'register.controls.previous' | translate}}</button>
                    <button class="mfs-control-button" (click)="nextStep(currentStep, registerFormGroup)">{{'register.controls.next' | translate}}</button>
            </div>

        </div>

        <!-- Accept terms and conditions -->
        <div class="mfs-terms-conditions pb-5" *ngIf="currentStep == 4 && !termsAccepted" data-aos="flip-left" data-aos-duration="500">
            <div class="terms-box">
                <div class="terms-text" *ngIf="preferredLang === 'es'">
                    <div class="terms-header">
                        <h2 class="text-center">{{'register.dataAuth' | translate }}</h2>
                    </div>

                    <section>
                        <p>
                            ESMERALDAS SANTA ROSA S.A. recolecta, almacena, usa, transfiere, transmite y en general trata de manera manual o automatizada datos personales en su calidad de Responsable de acuerdo con la Política de Tratamiento de Datos Personales y Aviso de privacidad disponibles para tu consulta en la navegación de la plataforma web.
                        </p>
                        <p>
                            En el proceso de registro como Usuario, he recibido información por parte de ESR acerca de mis derechos como titular de información personal, el carácter facultativo en la entrega de información sensible, y el canal dispuesto para la presentación de consultas y reclamos relacionados con mi información personal.
                        </p>
                        <p>
                            Con base en la información proporcionada, autorizo de manera voluntaria, previa, expresa e informada a ESR para tratar mis datos personales, y en especial:
                        </p>

                        <div class="d-flex auth-box">
                            <div class="d-flex align-items-center input-terms">
                                <input type="checkbox" (click)="termsAaccept=true" [disabled]="termsAaccept" [checked]="termsAaccept">
                                <p class="ms-2">Acepto</p>
                            </div>
                            <p>
                                <span class="bold-span-terms">Autorizo el tratamiento de mis datos para crear mi cuenta como Usuario</span>; gestionar el acceso a la plataforma por medio de un nombre de usuario y una contraseña (lo que implica la autorización de contacto para la recuperación de usuario o validación de identidad); construir mi perfil de usuario (lo que implica la proporciona de información de identificación, ubicación, información asociada a mi profesión u oficio, información de mi experiencia laboral, referencias personales, así como la carga voluntaria de datos sensibles como mi imagen de perfil); gestionar la realización de pruebas; administrar mi estado de aspirante o contratado por la organización, y en general autorizo el uso de mis datos personales para que se permita el uso de la plataforma.
                            </p>
                        </div>

                        <div class="d-flex auth-box">
                            <div class="d-flex align-items-center input-terms">
                                <input type="checkbox" (click)="termsBaccept=true" [disabled]="termsBaccept" [checked]="termsBaccept">
                                <p class="ms-2">Acepto</p>
                            </div>

                            <p>
                                <span class="bold-span-terms">Autorizo el tratamiento de mis datos para formar parte de un “Banco de Talento” de la organización</span>, y para que en el evento del desarrollo de procesos de selección, mi perfil pueda ser evaluado de acuerdo con el cumplimiento de los requisitos de las convocatorias y permitir el desarrollo de los respectivos procesos, lo cual puede implicar; la toma de exámenes; la realización de pruebas psicotécnicas y/o psicofísicas; rectificar la veracidad de la información aportada por el candidato y validar las referencias proporcionadas; la realización de estudios de seguridad; la consulta de antecedentes disciplinarios o en listas de control Nacional e Internacional relacionadas con Lavados de Activos y Financiación del Terrorismo; contactar para envío de comunicaciones y notificaciones relativas al desarrollo de proceso de selección; realizar campañas de actualización de datos; y conservar soporte histórico de la relación del titular con la organización.
                            </p>
                        </div>


                    </section>
                </div>

                <div class="terms-text" *ngIf="preferredLang === 'en'">
                    <div class="terms-header">
                        <h2 class="text-center">Authorization</h2>
                    </div>
        
                    <section>
                        <p>
                            ESMERALDAS SANTA ROSA S.A. collects, stores, uses, transfers, transmits, and generally processes personal data manually or automatically in its capacity as Responsible in accordance with the Personal Data Processing Policy and Privacy Notice available for your consultation on the web platform.
                        </p>
                        <p>
                            During the registration process as a User, I have received information from ESR about my rights as the owner of personal information, the optional nature of providing sensitive information, and the channel provided for submitting inquiries and complaints related to my personal information.
                        </p>
                        <p>
                            Based on the information provided, I voluntarily, previously, expressly, and informedly authorize ESR to process my personal data, and especially:
                        </p>
        
                        <div class="d-flex auth-box">
                            <div class="d-flex align-items-center input-terms">
                                <input type="checkbox" (click)="termsAaccept=true" [disabled]="termsAaccept" [checked]="termsAaccept">
                                <p class="ms-2">I accept</p>
                            </div>
                            <p>
                                <span class="bold-span-terms">I authorize the processing of my data to create my User account</span>; manage access to the platform through a username and password (which implies the authorization to contact for user recovery or identity validation); build my user profile (which implies the provision of identification information, location, information associated with my profession or trade, information about my work experience, personal references, as well as the voluntary upload of sensitive data such as my profile image); manage the performance of tests; administer my status as an applicant or hired by the organization, and generally authorize the use of my personal data to allow the use of the platform.
                            </p>
                        </div>
        
                        <div class="d-flex auth-box">
                            <div class="d-flex align-items-center input-terms">
                                <input type="checkbox" (click)="termsBaccept=true" [disabled]="termsBaccept" [checked]="termsBaccept">
                                <p class="ms-2">I accept</p>
                            </div>
        
                            <p>
                                <span class="bold-span-terms">I authorize the processing of my data to be part of a "Talent Pool" of the organization</span>, and so that in the event of the development of selection processes, my profile can be evaluated according to the fulfillment of the requirements of the calls and allow the development of the respective processes, which may involve; taking exams; conducting psychotechnical and/or psychophysical tests; verifying the truthfulness of the information provided by the candidate and validating the references provided; conducting security studies; consulting disciplinary records or on National and International control lists related to Money Laundering and Terrorism Financing; contacting to send communications and notifications related to the development of the selection process; carrying out data update campaigns; and keeping historical support of the holder's relationship with the organization.
                            </p>
                        </div>
                    </section>
                </div>

                <hr>
                <div class="mfs-controls-div mt-3">
                    <button  class="mfs-control-button"
                    (click)="previousStep()">{{'register.controls.previous' | translate}}</button>

                    <button class="mfs-control-button" (click)="termsAccepted = true;"
                        type="submit" [disabled]="!termsAaccept || !termsBaccept">{{'register.controls.next'
                        | translate}}</button>
                </div>
            </div>
        </div>

        <!-- Data treatment -->
        <div class="mfs-terms-conditions second-part pb-5" *ngIf="currentStep == 4 && termsAccepted" data-aos="flip-left" data-aos-duration="500">
            <div class="terms-box">
                <div class="terms-text" *ngIf="preferredLang === 'es'">
                    <div class="terms-header mb-2">
                        <h4 class="text-center special-title">TÉRMINOS Y CONDICIONES PARA LA NAVEGACIÓN Y USO DE LA PLATAFORMA WEB ESR-OP</h4>

                    </div>
                    <section>
                        <h4>1. BIENVENIDOS A LA PLATAFORMA WEB ESR-OP</h4>
                        <p>Bienvenido a ESR-OP, la plataforma virtual para la gestión de personal, operaciones y oportunidades de empleo de ESMERALDAS SANTA ROSA S.A., una sociedad comercial legalmente constituida bajo las leyes de Colombia, identificada con NIT 800.231.848-1 (Quien para todos los efectos del presente documento también podrá denominarse la EMPRESA o la SOCIEDAD), cuyo objeto social es principalmente la extracción de esmeraldas, piedras preciosas y semipreciosas.</p>
                        <p>Te invitamos a que leas atentamente el contenido de los presentes Términos y Condiciones para la navegación y uso de la plataforma web ESR-OP (En adelante ESR-OP, la PLATAFORMA, o la PLATAFORMA WEB), pues el acceso, la navegación y el uso en general de la PLATAFORMA quedarán sujetos a las disposiciones contenidas en el presente documento y a la legislación colombiana vigente y aplicable según la materia.</p>
                    
                        <h4>2. TÉRMINOS Y CONDICIONES OBLIGATORIOS Y VINCULANTES</h4>
                        <h4>2.1 Obligatoriedad</h4>
                        <p>Ten presente que, para acceder, navegar, registrarse y realizar cualquier operación como Usuario de la PLATAFORMA, es siempre obligatoria la aceptación de los Términos y Condiciones contenidos en este documento.</p>
                    
                        <h4>2.2 Capacidad Legal</h4>
                        <p>Solamente podrán acceder, navegar, registrarse, usar o realizar cualquier operación a través de ESR-OP, las personas que sean legalmente capaces para ejercer derechos y contraer obligaciones según la ley colombiana. Es decir, el uso de la PLATAFORMA WEB se encuentra habilitado para personas mayores de dieciocho (18) años.</p>
                    
                        <h4>2.3 Territorio</h4>
                        <p>ESR-OP es una PLATAFORMA WEB dirigida exclusivamente al territorio colombiano, por lo tanto, ESMERALDAS SANTA ROSA S.A. no se responsabiliza por la operación de la PLATAFORMA, ni por la legalidad ni disponibilidad de su contenido, cuando se acceda a la misma fuera del territorio colombiano. Si decides acceder por tu propia iniciativa a ESR-OP desde fuera del territorio colombiano, dicho acceso se realiza bajo tu responsabilidad, y con el consentimiento que dicho acceso se sujetará a las leyes colombianas.</p>
                    
                        <h4>2.4 Aceptación</h4>
                        <p>Navegar, registrarse y realizar operaciones de Usuario a través de la PLATAFORMA implica la aceptación expresa y vinculante de la totalidad del contenido de los presentes Términos y Condiciones. En caso de que no estés de acuerdo con estos Términos y Condiciones, debes abstenerte de acceder, navegar, registrarte o realizar cualquier tipo de operación a través de ESR-OP.</p>
                    
                        <h4>2.5 Modificación</h4>
                        <p>En virtud de la autonomía privada de la voluntad de ESMERALDAS SANTA ROSA S.A., los presentes Términos y Condiciones pueden en cualquier momento ser revisados, ajustados o modificados, unilateralmente por parte de la EMPRESA, sin que sea necesario solicitar autorización a los Usuarios de la PLATAFORMA.</p>
                        <p>En caso de presentarse modificaciones en los Términos y Condiciones, ESMERALDAS SANTA ROSA S.A. publicará en la PLATAFORMA las eventuales versiones modificadas de los Términos y Condiciones, las cuales entrarán en vigor desde su publicación y una vez su contenido sea accesible a través de la PLATAFORMA. En dicho caso, los Usuarios deberán decidir si, aceptan las eventuales versiones modificadas de los Términos y Condiciones de la PLATAFORMA para continuar usando ESR-OP, o si en su lugar, rechazan las modificaciones y en consecuencia se verían obligados a cesar el uso de la PLATAFORMA y a cerrar sus respectivas cuentas de Usuarios. Es obligación de la EMPRESA permitir a los Usuarios el acceso y lectura de las versiones modificadas de los Términos y Condiciones para que aquellos puedan tomar su decisión de aceptación o rechazo con las consecuencias indicadas.</p>
                    
                        <h4>3. LA PLATAFORMA WEB ESR-OP</h4>
                        <p>ESR-OP es una plataforma virtual de gestión de personal, operaciones y oportunidades de empleo, mediante la cual los Usuarios ubicados dentro del territorio colombiano pueden crear su perfil personal, aplicar a vacantes en la EMPRESA y gestionar su información personal como trabajadores de ESMERALDAS SANTA ROSA S.A.</p>
                        <p>De manera particular, la PLATAFORMA permite: 1) la realización de consultas; 2) el registro como Usuario de acuerdo con los perfiles descritos en el capítulo quinto de los presentes Términos y Condiciones; 3) la construcción de un perfil con la proporción de documentos e información personal; 4) aplicar a vacantes u oportunidades de empleo en ESMERALDAS SANTA ROSA S.A.; 5) gestionar la información personal de los trabajadores de la EMPRESA de acuerdo con los requerimientos de la ley laboral colombiana. Para la realización de las operaciones previamente descritas, ESMERALDAS SANTA ROSA S.A. presume de buena fe que dichas operaciones son efectuadas directamente por su titular, debidamente facultado para efectuarlas.</p>
                    
                        <h4>3.1 La realización de consultas</h4>
                        <p>Para la realización de consultas en ESR-OP a través del formulario ¿Necesitas ayuda?, no es necesario el registro del solicitante como Usuario de la PLATAFORMA WEB. Sin embargo, constituyen condiciones indispensables para permitir la realización de consultas a través de la PLATAFORMA WEB: El otorgamiento de información personal mínima para poder tramitar la consulta (Nombre y correo electrónico), y la autorización del tratamiento de datos personales de acuerdo con la Política de Tratamiento de Datos Personales de ESMERALDAS SANTA ROSA S.A.</p>
                    
                        <h4>3.2 El registro como Usuario</h4>
                        <p>El registro como Usuario de la PLATAFORMA podrá efectuarse de acuerdo con lo descrito en el capítulo quinto de los presentes Términos y Condiciones. Para la realización del registro constituyen condiciones indispensables: El otorgamiento de información personal de identificación y ubicación (Incluyendo, pero sin limitarse a nombre, genero, fecha de nacimiento, dirección, nombre de usuario, correo electrónico e identificación); la autorización del tratamiento de datos personales de acuerdo con la Política de Tratamiento de Datos Personales de ESMERALDAS SANTA ROSA S.A. y la aceptación de los Términos y Condiciones de la PLATAFORMA.</p>
                    
                        <h4>3.3 La creación del perfil de usuario</h4>
                        <p>Finalizado el registro en la PLATAFORMA, los Usuarios registrados pueden alimentar su perfil en la Plataforma por medio de la proporción de documentos personales (como cedula, RUT o certificaciones laborales) y de la entrega de información personal y/o familiar (como: nombre completo, identificación, imagen personal, direcciones de contacto, la conformación de su círculo familiar y la indicación de recomendaciones personales). La proporción de información por parte del Usuario en la creación de su perfil es totalmente libre y voluntaria, y su recolección, almacenamiento y tratamiento en general si rige por los presentes Términos y Condiciones y la Política de Tratamiento de Datos Personales de ESMERALDAS SANTA ROSA S.A.</p>
                    
                        <h4>3.4 La aplicación a oportunidades de empleo</h4>
                        <p>Finalizado el registro en la PLATAFORMA, los Usuarios registrados pueden libre y voluntariamente aplicar a oportunidades de empleo o vacantes en ESMERALDAS SANTA ROSA S.A. Una vez se haya aplicado a una vacante, la información personal del Usuario será tratada de acuerdo con la Política de Tratamiento de Datos Personales de ESMERALDAS SANTA ROSA S.A. y los presentes Términos y Condiciones. Cada vacante determinará los requisitos que deben ser cumplidos para aplicar a esta, así como los documentos que deberán ser proporcionados en dicho proceso.</p>
                    
                        <h4>3.5 Las operaciones de gestión de personal</h4>
                        <p>Finalizado el registro en la PLATAFORMA, los Usuarios que cuenten con una relación laboral vigente con ESMERALDAS SANTA ROSA S.A. contarán con su expediente laboral digital, y con su información personal permitirán que el área de recursos humanos de la EMPRESA realice ciertas operaciones en el marco de la relación laboral vigente (Como el control de exámenes médicos, control de ausencias y seguimiento a procesos disciplinarios). La información personal del Usuario será tratada de acuerdo con la Política de Tratamiento de Datos Personales de ESMERALDAS SANTA ROSA S.A. y los presentes Términos y Condiciones.</p>
                    
                        <h4>4. LA INFORMACIÓN EN LA PLATAFORMA WEB</h4>
                        <p>Cada Usuario de ESR-OP es responsable de la información que comparta a través de la PLATAFORMA. Por lo tanto, cada Usuario al proporcionar cualquier tipo de información a través de la PLATAFORMA declara bajo juramento que la misma corresponde a información veraz y vigente, la cual está autorizado a compartir. ESMERALDAS SANTA ROSA S.A. no se hace responsable por la certeza o veracidad de la información o los datos personales otorgados y/o suministrados por sus Usuarios.</p>
                        <p>Cuando un Usuario por su voluntad decida proporcionar cualquier tipo de información a través de la PLATAFORMA declara y acepta expresamente que: a) Es titular de la información proporcionada, o que está autorizado para compartir la información de terceros. b) La información proporcionada es veraz y actualizada. c) La carga de información en la PLATAFORMA no vulnera los derechos de terceros. c) El Usuario autoriza a ESMERALDAS SANTA ROSA S.A. para recolectar, almacenar, usar, compilar, transmitir, transferir y en general suprimir la información proporcionada a través de la PLATAFORMA en los Términos y Condiciones contenidos en el presente documento y en la Política de tratamiento de Datos Personales.</p>
                        <p>Está expresamente prohibido para los Usuarios de la PLATAFORMA proporcionar información o material que: a) Esté protegido por las leyes de la propiedad intelectual; b) revele secretos industriales o comerciales; c) sea obsceno, difamatorio, abusivo, amenazante u ofensivo para cualquier tercero; d) sea pornográfico o que incluya sexo explícito; e) sea publicidad, cadenas de cartas, virus o cualquier programa con la intención de dañar, interferir, interceptar o apropiarse de cualquier sistema, datos o información de la PLATAFORMA.</p>
                        <p>En consecuencia de las anteriores declaraciones, el Usuario se obliga a responder por cualquier reclamo, queja, demanda o denuncia que contra ESR, interponga un tercero, con ocasión de la información proporcionada en la PLATAFORMA, exonerando y manteniendo indemne de cualquier responsabilidad a ESMERALDAS SANTA ROSA S.A.</p>
                    
                        <h4>5. LOS USUARIOS</h4>
                        <p>Cada persona podrá ser titular únicamente de un Usuario en la PLATAFORMA. Los Usuario de ESR-OP son exclusivamente personas naturales, ubicadas en el territorio colombiano, que en todo caso son capaces legalmente (mayores de 18 años) y por voluntad propia deciden visitar la PLATAFORMA WEB y registrarse en ella. La EMPRESA presume de buena fe que las acciones efectuadas en la PLATAFORMA son realizadas directamente por sus Usuarios, quienes se encuentran debidamente facultados para efectuar dichas operaciones. En la PLATAFORMA existen los siguientes tipos de Usuarios:</p>
                    
                        <h4>5.1 Usuarios Visitantes</h4>
                        <p>Corresponde a los Usuarios de la PLATAFORMA, quienes sin adelantar su registro en aquella, pueden navegar de manera limitada en la PLATAFORMA y realizar consultas o solicitudes varias en el formulario “¿Necesitas Ayuda?”. Para enviar una consulta o solicitud, será indispensable que el Usuario autorice el tratamiento de sus datos personales de manera expresa.</p>
                    
                        <h4>5.2 Usuarios Registrados</h4>
                        <p>Corresponde a los Usuarios de la PLATAFORMA, quienes desarrollan el proceso de registro como Candidatos a Procesos de Selección o como Trabajadores Actuales de la EMPRESA. Los Usuarios Registrados pueden navegar a través de la PLATAFORMA; crear su perfil de Usuario; actualizar su información personal; cargar documentos o aplicar a oportunidades de empleo. En el evento que el Usuario Registrado cuente con una relación laboral vigente con la EMPRESA, su perfil personal permitirá el desarrollo de operaciones por parte del área de Recursos Humanos, como el control de exámenes médicos, control de ausencias, seguimiento a procesos disciplinarios entre otros.</p>
                        <p>Para realizar el proceso de registro, será indispensable que el Usuario autorice el tratamiento de sus datos personales y acepte los presentes Términos y Condiciones de Uso de la PLATAFORMA, de manera expresa.</p>
                        <p>Una vez realizado el registro con éxito, se asignará un usuario personal y una contraseña de uso personal, intransferible y de responsabilidad y custodia exclusiva del Usuario Registrado. Para el manejo de la contraseña el Usuario Registrado deberá adoptar todas las medidas de seguridad necesarias para evitar su conocimiento por terceros, su mal uso o uso fraudulento. Se recomienda que la clave conste de números, letras y caracteres especiales, y que la misma sea renovada constantemente por lo menos tres (3) veces al año. En caso de pérdida u olvido de la contraseña, la misma podrá ser recuperada a través de la PLATAFORMA y la recepción de una notificación al correo electrónico registrado.</p>
                        <p>ESMERALDAS SANTA ROSA S.A. se reserva el derecho de eliminar o cerrar cualquier cuenta o registro de un Usuario cuando evidencie cualquier tipo de uso fraudulento, contrario a la buena fe, ilegal o que vulnere derechos de terceros, sin que medie autorización del Usuario, obligación de conservar copias de seguridad o restitución de cualquier tipo de datos. Adicionalmente, en dichos casos y si así lo decide ESR, la EMPRESA también estará facultada para iniciar las acciones legales que considere pertinentes en contra del Usuario Registrado.</p>
                    
                        <h4>6. PROPIEDAD INTELECTUAL</h4>
                        <h4>8.1 Restricciones de uso</h4>
                        <p>Mientras se esté navegando en la PLATAFORMA WEB, todo Usuario deberá tener presente que el contenido que ve, oye, lee o al que accede en ESR-OP, así como el software que permite el desarrollo y funcionamiento de la PLATAFORMA, tiene derechos de propiedad intelectual y se encuentra dispuesto para el público, para que se haga un uso exclusivamente personal y privado del mismo.</p>
                        <p>De ninguna forma, el uso, navegación o creación de usuarios a través de la PLATAFORMA implica o constituye la transferencia, cesión o autorización de los derechos de cualquiera de los activos de propiedad intelectual que son accesibles en ESR-OP, a menos que la misma sea concedida directa y expresamente por escrito por parte de la EMPRESA.</p>
                    
                        <h4>8.2 Prohibiciones</h4>
                        <p>El Usuario comprende y acepta que todo contenido de propiedad intelectual accesible en ESR-OP, está protegido por las normas de propiedad industrial o de derechos de autor (Con contenido de propiedad intelectual nos referimos, sin limitarnos exclusivamente a: marcas, enseñas, logos, modelos de utilidad, diseños industriales; material informático, gráfico, publicitario, de multimedia, audiovisual y/o de diseño; las imágenes, ilustraciones o fotografías; los contenidos, como textos y bases de datos; y las demás creaciones de propiedad industrial o de derechos de autor).</p>
                        <p>Queda expresamente prohibido a los Usuarios de la PLATAFORMA el uso comercial, todo acto de copia, la reproducción, distribución, venta comunicación al público, puesta a disposición del público, transformación, modificación, licencia, cesión y cualquier otro acto o modalidad de explotación de contenido de propiedad intelectual en cualquier medio o formato online u offline, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole.</p>
                        <p>Cualquier uso no autorizado de dicha propiedad intelectual constituirá una violación a los presentes Términos y Condiciones y a las normas vigentes nacionales e internacionales que le sean aplicables, por lo que la EMPRESA se encontrará facultada para iniciar las acciones legales que considere pertinentes en contra de los infractores.</p>
                    
                        <h4>7. PROTECCIÓN DE DATOS PERSONALES</h4>
                        <p>ESMERALDAS SANTA ROSA S.A. garantiza la protección de los derechos de Hábeas Data, privacidad, intimidad, buen nombre e imagen de todos los Usuarios de ESR-OP que proporcionen su información personal durante el uso y navegación de la PLATAFORMA WEB. Con tal propósito todas las actuaciones relacionadas con el tratamiento de datos personales se regirán siempre por los principios de buena fe, legalidad, libertad, finalidad, veracidad, transparencia, confidencialidad, seguridad y circulación restringida de la información personal, además de regirse por las normas legales que sean aplicables a la materia en el territorio colombiano.</p>
                        <p>ESMERALDAS SANTA ROSA S.A. se compromete con todo aquel que entregue su información personal a la EMPRESA durante el uso o navegación en ESR-OP, a no revelar con terceros dicha información, a no utilizarla con fines distintos de los autorizados por sus titulares, y a tratarla con las medidas de seguridad necesarias para asegurar su confidencialidad, reserva, seguridad y circulación restringida.</p>
                    
                        <h4>9.1 Remisión a política</h4>
                        <p>Las finalidades y uso dado a los datos personales recolectados a través de ESR-OP, los canales de atención, los derechos de los titulares, las formas de ejercerlos, entre otros lineamientos relacionados con la administración de datos personales, son de libre consulta y se encuentran a disposición de los usuarios en nuestra Política de Tratamiento de la Datos Personales, la cual además de hacer parte integral del presente documento, está disponible para su consulta en la PLATAFORMA WEB.</p>
                    
                        <h4>8. RESPONSABILIDAD DE LA EMPRESA</h4>
                        <p>La responsabilidad de ESMERALDAS SANTA ROSA S.A. en relación con el uso y navegación en la PLATAFORMA WEB, abarca únicamente las obligaciones legales aplicables al territorio colombiano como responsable del tratamiento de datos personales.</p>
                    
                        <h4>8.1 Exoneraciones</h4>
                        <p>Teniendo en cuenta que ESR-OP es una plataforma para la gestión de personal, operaciones y oportunidades de empleo de ESMERALDAS SANTA ROSA S.A., la EMPRESA no se hace responsable por fallas, errores, problemas técnicos o interrupciones en la operación o utilización de la PLATAFORMA WEB o de alguno de sus módulos. Fallas que incluyen, pero no se limitan a problemas de conectividad con el servidor, interrupción de comunicaciones, daño en equipos, problemas de conexión a internet o de cortes de energía.</p>
                        <p>A través de ESR-OP, Usuarios comparten sus imágenes e información personal en general, la cual declaran que es propia, verídica y entregada de manera voluntaria. Por lo anterior, es de la entera responsabilidad de los Usuarios la veracidad de la información compartida a través de LA PLATAFORMA. En consecuencia ESMERALDAS SANTA ROSA S.A. no será responsable por ningún tipo de violación del derecho a la protección de datos o de propiedad intelectual, que se ocasione con motivo de contenido cargado o compartido por Usuarios, quienes se obligan a mantener indemne a ESMERALDAS SANTA ROSA S.A. respecto de cualquier reclamación.</p>
                        <p>Por lo anterior es claro y aceptado por los Usuarios, que cualquier tipo de Usuario es el único responsable por los daños que se puedan ocasionar por: La veracidad de la información suministrada en ESR-OP o su inexactitud y las licencias o autorizaciones de propiedad intelectual que se requieran para cargar y compartir contenido en la PLATAFORMA WEB. También es claro y aceptado por el usuario, que ESMERALDAS SANTA ROSA S.A. bajo ninguna circunstancia, será responsable por: Interrupciones en el uso de la PLATAFORMA WEB; existencia de virus; suplantaciones personales que realice cualquier persona; las infracciones de propiedad intelectual que pudiesen presentarse con ocasión de licencias o autorizaciones del contenido cargado por Usuarios; hechos de la naturaleza, o por las demás situaciones de fuerza mayor o caso fortuito que pudiesen afectar la operación de la PLATAFORMA.</p>
                    
                        <h4>8.2 Limitación de la responsabilidad</h4>
                        <p>En cualquier caso y por cualquier motivo, la responsabilidad de ESMERALDAS SANTA ROSA S.A. será limitada de forma económica, hasta una cuantía de diez salarios mínimos mensuales legales vigentes (10SMMLV). Los Usuarios, aceptan expresamente la limitación de responsabilidad para la EMPRESA y exoneran a ESMERALDAS SANTA ROSA S.A. de cualquier tipo de responsabilidad que supere dicha cuantía.</p>
                    
                        <h4>9. SEPARACIÓN DE ESTIPULACIONES</h4>
                        <p>Dado el caso de que alguna de las estipulaciones contenidas en los presentes Términos y Condiciones llegare a ser declarada ineficaz, nula o ilegal, dicha estipulación no afectará la eficacia, validez, legalidad o exigibilidad del resto de las estipulaciones que forman parte de los presentes Términos y Condiciones.</p>
                    
                        <h4>10. JURISDICCIÓN Y LEY APLICABLE</h4>
                        <p>Los actos celebrados a través de ESR-OP y cualquier reclamación relacionada con la PLATAFORMA WEB, se regirán por la jurisdicción y legislación colombiana. Así entonces, cualquier controversia que surja o guarde relación con la operación de ESR-OP será sometida a la jurisdicción no exclusiva de los juzgados y tribunales colombianos.</p>
                    
                        <h4>11. NOTIFICACIONES Y CONTACTO</h4>
                        <p>Para contactarse con ESMERALDAS SANTA ROSA S.A., puede hacer uso de nuestro formulario de contacto dispuesto en la página <a href="http://www.esr-op.com">www.esr-op.com</a> o escribiéndonos al mail <a href="mailto:informacion@esmeraldassantarosa.com">{{'informacion@esmeraldassantarosa.com'}}</a>.</p>
                    </section>

                    <hr>

                    <div class="d-flex flex-column final-accept-check">
                        <p class="text-center">{{getFullName()}} acepto expresamente los Terminos y Condiciones expuestos previamente, los cuales
                            rigen en exclusiva el acceso, navegación, registro y cualquier operación que se ejecute
                            en la Plataforma ESR-OP.</p>
                        <input type="checkbox" (click)="finalAccept=true" [disabled]="finalAccept" [checked]="finalAccept">
                    </div>
                </div>

                <div class="terms-text" *ngIf="preferredLang === 'en'">
                    <div class="terms-header mb-2">
                        <h4 class="text-center special-title">TERMS AND CONDITIONS FOR THE NAVIGATION AND USE OF THE ESR-OP WEB PLATFORM</h4>
                    </div>
                    <section>
                        <h4>1. WELCOME TO THE ESR-OP WEB PLATFORM</h4>
                        <p>Welcome to ESR-OP, the virtual platform for the management of personnel, operations, and job opportunities of ESMERALDAS SANTA ROSA S.A., a commercial company legally constituted under the laws of Colombia, identified with NIT 800.231.848-1 (which for all purposes of this document may also be referred to as the COMPANY or the SOCIETY), whose main corporate purpose is the extraction of emeralds, precious and semi-precious stones.</p>
                        <p>We invite you to carefully read the content of these Terms and Conditions for navigating and using the ESR-OP web platform (hereinafter referred to as ESR-OP, the PLATFORM, or the WEB PLATFORM), as access, navigation, and general use of the PLATFORM will be subject to the provisions contained in this document and the applicable current Colombian legislation.</p>
                
                        <h4>2. MANDATORY AND BINDING TERMS AND CONDITIONS</h4>
                        <h4>2.1 Obligatoriness</h4>
                        <p>Keep in mind that, to access, navigate, register, and perform any operation as a User of the PLATFORM, acceptance of the Terms and Conditions contained in this document is always mandatory.</p>
                
                        <h4>2.2 Legal Capacity</h4>
                        <p>Only people who are legally capable of exercising rights and assuming obligations under Colombian law can access, navigate, register, use, or perform any operation through ESR-OP. This means the use of the WEB PLATFORM is enabled for people over eighteen (18) years old.</p>
                
                        <h4>2.3 Territory</h4>
                        <p>ESR-OP is a WEB PLATFORM exclusively directed to the Colombian territory; therefore, ESMERALDAS SANTA ROSA S.A. is not responsible for the operation of the PLATFORM, nor for the legality or availability of its content when accessed outside the Colombian territory. If you decide to access ESR-OP on your own initiative from outside the Colombian territory, such access is at your own responsibility, and you consent that such access will be subject to Colombian laws.</p>
                
                        <h4>2.4 Acceptance</h4>
                        <p>Browsing, registering, and performing User operations through the PLATFORM implies express and binding acceptance of all the content of these Terms and Conditions. If you do not agree with these Terms and Conditions, you must refrain from accessing, navigating, registering, or performing any type of operation through ESR-OP.</p>
                
                        <h4>2.5 Modification</h4>
                        <p>By virtue of the private autonomy of the will of ESMERALDAS SANTA ROSA S.A., these Terms and Conditions can be reviewed, adjusted, or modified at any time, unilaterally by the COMPANY, without needing to request authorization from the Users of the PLATFORM.</p>
                        <p>In the event of modifications to the Terms and Conditions, ESMERALDAS SANTA ROSA S.A. will publish the modified versions of the Terms and Conditions on the PLATFORM, which will take effect from their publication and once their content is accessible through the PLATFORM. In such a case, Users must decide whether to accept the modified versions of the PLATFORM's Terms and Conditions to continue using ESR-OP, or if instead, they reject the modifications and consequently would be obliged to stop using the PLATFORM and close their respective User accounts. It is the COMPANY's obligation to allow Users access and read the modified versions of the Terms and Conditions so that they can make their decision to accept or reject them with the indicated consequences.</p>
                
                        <h4>3. THE ESR-OP WEB PLATFORM</h4>
                        <p>ESR-OP is a virtual platform for the management of personnel, operations, and job opportunities, through which Users located within the Colombian territory can create their personal profile, apply for vacancies at the COMPANY, and manage their personal information as workers of ESMERALDAS SANTA ROSA S.A.</p>
                        <p>In particular, the PLATFORM allows: 1) making inquiries; 2) registering as a User according to the profiles described in chapter five of these Terms and Conditions; 3) creating a profile with the provision of documents and personal information; 4) applying for job vacancies or opportunities at ESMERALDAS SANTA ROSA S.A.; 5) managing personal information of the COMPANY's workers according to the requirements of Colombian labor law. For the realization of the previously described operations, ESMERALDAS SANTA ROSA S.A. presumes in good faith that such operations are carried out directly by their holder, duly authorized to perform them.</p>
                
                        <h4>3.1 Making Inquiries</h4>
                        <p>To make inquiries on ESR-OP through the "Need help?" form, it is not necessary to register the applicant as a User of the WEB PLATFORM. However, it is essential to provide the minimum personal information to process the inquiry (Name and email), and authorization for the processing of personal data according to the Personal Data Processing Policy of ESMERALDAS SANTA ROSA S.A.</p>
                
                        <h4>3.2 User Registration</h4>
                        <p>User registration on the PLATFORM can be carried out as described in chapter five of these Terms and Conditions. For registration, it is essential to provide personal identification and location information (including but not limited to name, gender, date of birth, address, username, email, and identification); authorization for the processing of personal data according to the Personal Data Processing Policy of ESMERALDAS SANTA ROSA S.A.; and acceptance of the PLATFORM's Terms and Conditions.</p>
                
                        <h4>3.3 Creating the User Profile</h4>
                        <p>After registering on the PLATFORM, registered Users can populate their profile on the Platform by providing personal documents (such as ID, RUT, or work certificates) and personal and/or family information (such as: full name, identification, personal image, contact addresses, the composition of their family circle, and indication of personal recommendations). The provision of information by the User in creating their profile is entirely free and voluntary, and its collection, storage, and processing in general are governed by these Terms and Conditions and the Personal Data Processing Policy of ESMERALDAS SANTA ROSA S.A.</p>
                
                        <h4>3.4 Applying for Job Opportunities</h4>
                        <p>After registering on the PLATFORM, registered Users can freely and voluntarily apply for job opportunities or vacancies at ESMERALDAS SANTA ROSA S.A. Once a vacancy has been applied for, the User's personal information will be processed according to the Personal Data Processing Policy of ESMERALDAS SANTA ROSA S.A. and these Terms and Conditions. Each vacancy will determine the requirements that must be met to apply, as well as the documents that must be provided in this process.</p>
                
                        <h4>3.5 Personnel Management Operations</h4>
                        <p>After registering on the PLATFORM, Users with a current employment relationship with ESMERALDAS SANTA ROSA S.A. will have their digital employment file, and with their personal information, will allow the company's human resources area to carry out certain operations within the framework of the current employment relationship (such as medical examination control, absence control, and follow-up of disciplinary processes). The User's personal information will be processed according to the Personal Data Processing Policy of ESMERALDAS SANTA ROSA S.A. and these Terms and Conditions.</p>
                
                        <h4>4. INFORMATION ON THE WEB PLATFORM</h4>
                        <p>Each User of ESR-OP is responsible for the information they share through the PLATFORM. Therefore, each User, when providing any type of information through the PLATFORM, declares under oath that it corresponds to truthful and current information, which they are authorized to share. ESMERALDAS SANTA ROSA S.A. is not responsible for the accuracy or truthfulness of the information or personal data provided and/or supplied by its Users.</p>
                        <p>When a User voluntarily decides to provide any type of information through the PLATFORM, they expressly declare and accept that: a) They are the owner of the provided information, or are authorized to share the information of third parties. b) The provided information is truthful and updated. c) Uploading information to the PLATFORM does not violate the rights of third parties. c) The User authorizes ESMERALDAS SANTA ROSA S.A. to collect, store, use, compile, transmit, transfer, and generally delete the provided information through the PLATFORM under the Terms and Conditions contained in this document and the Personal Data Processing Policy.</p>
                        <p>It is expressly prohibited for PLATFORM Users to provide information or material that: a) Is protected by intellectual property laws; b) reveals industrial or commercial secrets; c) is obscene, defamatory, abusive, threatening, or offensive to any third party; d) is pornographic or includes explicit sex; e) is advertising, chain letters, viruses, or any program intended to damage, interfere with, intercept, or appropriate any system, data, or information from the PLATFORM.</p>
                        <p>Consequently, the User is obliged to respond to any claim, complaint, demand, or report against ESR, filed by a third party, due to the information provided on the PLATFORM, exonerating and keeping ESMERALDAS SANTA ROSA S.A. free from any responsibility.</p>
                
                        <h4>5. THE USERS</h4>
                        <p>Each person can hold only one User account on the PLATFORM. ESR-OP Users are exclusively natural persons, located in the Colombian territory, who are legally capable (over 18 years old) and voluntarily decide to visit the WEB PLATFORM and register on it. The COMPANY presumes in good faith that the actions carried out on the PLATFORM are performed directly by its Users, who are duly authorized to carry out such operations. The PLATFORM has the following types of Users:</p>
                
                        <h4>5.1 Visitor Users</h4>
                        <p>These are PLATFORM Users who, without registering on it, can navigate the PLATFORM in a limited way and make various inquiries or requests in the "Need Help?" form. To send an inquiry or request, it is essential that the User expressly authorizes the processing of their personal data.</p>
                
                        <h4>5.2 Registered Users</h4>
                        <p>These are PLATFORM Users who undergo the registration process as Candidates for Selection Processes or as Current Workers of the COMPANY. Registered Users can navigate through the PLATFORM; create their User profile; update their personal information; upload documents or apply for job opportunities. If the Registered User has a current employment relationship with the COMPANY, their personal profile will allow the development of operations by the Human Resources area, such as medical examination control, absence control, follow-up of disciplinary processes, among others.</p>
                        <p>To complete the registration process, it is essential that the User authorizes the processing of their personal data and expressly accepts these Terms and Conditions of Use of the PLATFORM.</p>
                        <p>Once the registration is successfully completed, a personal user and a personal, non-transferable password of exclusive responsibility and custody of the Registered User will be assigned. For password management, the Registered User must take all necessary security measures to prevent its knowledge by third parties, its misuse, or fraudulent use. It is recommended that the password consists of numbers, letters, and special characters, and that it be renewed constantly at least three (3) times a year. In case of loss or forgetfulness of the password, it can be recovered through the PLATFORM and the reception of a notification to the registered email.</p>
                        <p>ESMERALDAS SANTA ROSA S.A. reserves the right to delete or close any User account or registration when any type of fraudulent use, contrary to good faith, illegal or infringing third-party rights is evidenced, without the need for User authorization, obligation to keep backup copies, or restitution of any type of data. Additionally, in such cases and if ESR so decides, the COMPANY will also be entitled to initiate the legal actions it deems appropriate against the Registered User.</p>
                
                        <h4>6. INTELLECTUAL PROPERTY</h4>
                        <h4>8.1 Usage Restrictions</h4>
                        <p>While navigating the WEB PLATFORM, every User should keep in mind that the content they see, hear, read, or access on ESR-OP, as well as the software that allows the PLATFORM's development and operation, has intellectual property rights and is made available to the public for exclusively personal and private use.</p>
                        <p>In no way does the use, navigation, or creation of users through the PLATFORM imply or constitute the transfer, assignment, or authorization of the rights of any of the intellectual property assets accessible on ESR-OP, unless it is directly and expressly granted in writing by the COMPANY.</p>
                
                        <h4>8.2 Prohibitions</h4>
                        <p>The User understands and accepts that all intellectual property content accessible on ESR-OP is protected by industrial property or copyright laws (with intellectual property content, we refer, without being exclusively limited to: trademarks, signs, logos, utility models, industrial designs; computer, graphic, advertising, multimedia, audiovisual, and/or design material; images, illustrations, or photographs; content such as texts and databases; and other industrial property or copyright creations).</p>
                        <p>PLATFORM Users are expressly prohibited from commercial use, copying, reproduction, distribution, public communication, making available to the public, transformation, modification, licensing, assignment, and any other act or mode of exploitation of intellectual property content in any medium or format online or offline, including, but not limited to, electronic, mechanical, photocopying, recording, or any other kind.</p>
                        <p>Any unauthorized use of such intellectual property will constitute a violation of these Terms and Conditions and the applicable national and international regulations, for which the COMPANY will be entitled to initiate the legal actions it deems appropriate against the infringers.</p>
                
                        <h4>7. PERSONAL DATA PROTECTION</h4>
                        <p>ESMERALDAS SANTA ROSA S.A. guarantees the protection of the rights of Habeas Data, privacy, intimacy, good name, and image of all ESR-OP Users who provide their personal information during the use and navigation of the WEB PLATFORM. For this purpose, all actions related to personal data processing will always be governed by the principles of good faith, legality, freedom, purpose, truthfulness, transparency, confidentiality, security, and restricted circulation of personal information, in addition to being governed by the applicable legal standards in Colombian territory.</p>
                        <p>ESMERALDAS SANTA ROSA S.A. undertakes with anyone who provides their personal information to the COMPANY during the use or navigation on ESR-OP, not to disclose such information to third parties, not to use it for purposes other than those authorized by their holders, and to process it with the necessary security measures to ensure its confidentiality, reserve, security, and restricted circulation.</p>
                
                        <h4>9.1 Policy Reference</h4>
                        <p>The purposes and use of the personal data collected through ESR-OP, the service channels, the rights of the holders, the ways to exercise them, among other guidelines related to personal data management, are freely available and can be consulted by users in our Personal Data Processing Policy, which in addition to being an integral part of this document, is available for consultation on the WEB PLATFORM.</p>
                
                        <h4>8. COMPANY RESPONSIBILITY</h4>
                        <p>ESMERALDAS SANTA ROSA S.A.'s responsibility in relation to the use and navigation on the WEB PLATFORM only encompasses the legal obligations applicable to the Colombian territory as the responsible entity for personal data processing.</p>
                
                        <h4>8.1 Exemptions</h4>
                        <p>Given that ESR-OP is a platform for managing personnel, operations, and job opportunities at ESMERALDAS SANTA ROSA S.A., the COMPANY is not responsible for failures, errors, technical problems, or interruptions in the operation or use of the WEB PLATFORM or any of its modules. Failures include, but are not limited to, connectivity issues with the server, communication interruptions, equipment damage, internet connection problems, or power outages.</p>
                        <p>Through ESR-OP, Users share their images and general personal information, which they declare is their own, truthful, and voluntarily provided. Therefore, the Users are entirely responsible for the truthfulness of the information shared through the PLATFORM. Consequently, ESMERALDAS SANTA ROSA S.A. will not be responsible for any type of violation of the right to data protection or intellectual property that occurs due to content uploaded or shared by Users, who agree to keep ESMERALDAS SANTA ROSA S.A. free from any claim.</p>
                        <p>Therefore, it is clear and accepted by the Users that any type of User is solely responsible for the damages that may be caused by: The truthfulness of the information provided on ESR-OP or its inaccuracy and the licenses or intellectual property authorizations required to upload and share content on the WEB PLATFORM. It is also clear and accepted by the user that ESMERALDAS SANTA ROSA S.A. under no circumstances, will be responsible for: Interruptions in the use of the WEB PLATFORM; the existence of viruses; personal impersonations carried out by any person; intellectual property infringements that may occur due to licenses or authorizations for content uploaded by Users; acts of nature, or other force majeure or fortuitous events that may affect the PLATFORM's operation.</p>
                
                        <h4>8.2 Limitation of Liability</h4>
                        <p>In any case and for any reason, the liability of ESMERALDAS SANTA ROSA S.A. will be economically limited to an amount of ten current legal monthly minimum wages (10SMMLV). Users expressly accept the limitation of liability for the COMPANY and exonerate ESMERALDAS SANTA ROSA S.A. from any type of liability exceeding that amount.</p>
                
                        <h4>9. SEPARATION OF PROVISIONS</h4>
                        <p>If any of the provisions contained in these Terms and Conditions is declared ineffective, null, or illegal, such provision will not affect the effectiveness, validity, legality, or enforceability of the remaining provisions that are part of these Terms and Conditions.</p>
                
                        <h4>10. JURISDICTION AND APPLICABLE LAW</h4>
                        <p>The acts carried out through ESR-OP and any claim related to the WEB PLATFORM will be governed by Colombian jurisdiction and legislation. Thus, any controversy arising or related to the operation of ESR-OP will be submitted to the non-exclusive jurisdiction of Colombian courts and tribunals.</p>
                
                        <h4>11. NOTIFICATIONS AND CONTACT</h4>
                        <p>To contact ESMERALDAS SANTA ROSA S.A., you can use our contact form available on the page <a href="http://www.esr-op.com">www.esr-op.com</a> or write to us at the email <a href="mailto:informacion@esmeraldassantarosa.com">{{'informacion@esmeraldassantarosa.com'}}</a>.</p>
                    </section>
                
                    <hr>
                
                    <div class="d-flex flex-column final-accept-check">
                        <p class="text-center">{{getFullName()}} expressly accept the previously stated Terms and Conditions, which exclusively govern access, navigation, registration, and any operation carried out on the ESR-OP Platform.</p>
                        <input type="checkbox" (click)="finalAccept=true" [disabled]="finalAccept" [checked]="finalAccept">
                    </div>
                </div>

                <hr>

                <div class="mfs-controls-div mt-3">
                    <button  class="mfs-control-button"
                    (click)="termsAccepted=false">{{'register.controls.previous' | translate}}</button>

                    <button class="mfs-control-button" (click)="registerUser()"
                        type="submit" [disabled]="!finalAccept || !termsAaccept || !termsBaccept ">{{'register.controls.create' | translate}}</button>
                </div>
            </div>
        </div>

        <!-- msf buttons controler -->
        <div class="mfs-controls-div" *ngIf="currentStep < 3">
            <button *ngIf="currentStep !== 1" class="mfs-control-button"
                (click)="previousStep()">{{'register.controls.previous' | translate}}</button>

            <button *ngIf="currentStep <= 3 && firstMessageContinue" class="mfs-control-button"
                (click)="nextStep(currentStep, registerFormGroup)">{{'register.controls.next'
                | translate}}</button>
        </div>
    </form>

    <!-- Succes card once the user is created-->
    <div class="mfs-user-created-message" *ngIf="userCreated" data-aos="fade-right" data-aos-duration="500">
        <div class="mfs-custom-card">
            <div class="mfs-form-card-title mb-4">
                <h2>{{'register.userRegistered.title' | translate }}</h2>
            </div>

            <p class="text-center">{{'register.userRegistered.description' | translate }}</p>
            <hr>
            <div class="mfs-controls-div mt-3 mb-3">
                <button class="mfs-control-button" (click)="redirectToLogin()">{{'register.userRegistered.button' | translate }}</button>
            </div>
        </div>
    </div>


</div>