<div class="not-found-container">
    <div class="not-found-wrapper d-flex flex-column justify-content-center align-items-center">
        <div class="login100-form-avatar">
            <img src="/assets/minerImages/iconMinnerWithPickAxe.png" alt="AVATAR">
        </div>
        <h1 class="text-center m-0">404!!!</h1>
        <h2 class="text-center">{{'others.generalTranslations.notFound' | translate}}</h2>
        <div class="d-flex flex-column w-100 justify-content-center align-items-center mt-2">
            <div class="btn mb-2" (click)="goBack()">{{'others.generalTranslations.goBack' | translate}}</div>
            <div class="btn" (click)="goToLanding()">{{'navBar.landing' | translate}}</div>
        </div>

    </div>

</div>


  