// toolsbar component ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---


// Imports =====================================================================================================
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// Interfaces/models ===========================================================================================
import { ISideBarOption } from '@core/models/ISideBarOption';
import { AuthServiceService } from '@shared/services/auth-service.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '@shared/services/utils.service';

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-tools-bar',
  templateUrl: './tools-bar.component.html',
  styleUrl: './tools-bar.component.css'
})
export class ToolsBarComponent implements OnInit{
    @Input() isToolbarOpne:boolean=false;
    //General Properties ========================================================================================
    public menuOpen:boolean=false
    public minerPng:string='assets/minerImages/minerSvgs/iconMinnerWithPickAxeV2-removebg-preview.png';
    
    public sideBarOptions:ISideBarOption[]=[
      {tooltip:"Positions",materialIcon:"assignment", route:['/','home','jobs'],show:true},
      {tooltip:"Applications",materialIcon:"work_history", route:['/','home','applications'],show:true},
      {tooltip:"Tests",materialIcon:"quiz", route:['/','home','trivia'],show:true},
      {tooltip:"Panel",materialIcon:"space_dashboard", route:['/','home','panel'],show:true}
    ]

  
    constructor(private router:Router, private modal:MatDialog, private authService:AuthServiceService, private translateService:TranslateService, private utilsService:UtilsService){}
    // LifeCycles ================================================================================================
    ngOnInit(): void {
      this.translateService.setDefaultLang('ES');
      this.translateService.use(localStorage.getItem('lang') || 'ES');
      this.sideBarOptions=[
        {tooltip:"Positions",materialIcon:"assignment", route:['/','home','jobs'],show:this.utilsService.checkPermissionAllowed(null,true,['admin','observer','associate','basic_user'],null,true)},
        {tooltip:"Applications",materialIcon:"work_history", route:['/','home','applications'],show:this.utilsService.checkPermissionAllowed(null,true,['admin','observer','associate','basic_user'],null,true)},
        {tooltip:"Tests",materialIcon:"quiz", route:['/','home','trivia'],show:this.utilsService.checkPermissionAllowed(null,true,[],null,true)},
        {tooltip:"Panel",materialIcon:"space_dashboard", route:['/','home','panel'],show:this.utilsService.checkPermissionAllowed(null,true,['admin','observer','associate'],null,true)}
      ]
    }

    // CustomFuntions ============================================================================================
    toolBarRedirect(sideBarOption:ISideBarOption):void{
      if(this.isToolbarOpne){
        let redirectRoute= sideBarOption['route'] || null;

        if (redirectRoute !== null){
          this.router.navigate(redirectRoute);
        }
      }

    }

    logOut(){
      if(this.isToolbarOpne){
        this.authService.logOut();
        this.router.navigate(['/','landing'])
      }

    }

}
