// Carrousel component ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---


// Imports =====================================================================================================
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceService } from '@shared/services/auth-service.service';
import { CookieService } from 'ngx-cookie-service';
import { faHome,IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';

// Interfaces/models ===========================================================================================
//---

// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  @Input() transparentHeader:boolean = false; 
  @Input() hideLangButton:boolean=false;
  //General Properties ========================================================================================
  public esmeraldasSantaRosaCropedIcon:string='assets/generalImages/otherImages/logoCroped.png'
  public lang:string='';
  public homeIcon:IconDefinition=faHome
  constructor(private router:Router, private translateService:TranslateService, private authService: AuthServiceService, private cookieService:CookieService){

  }

  // LifeCycles ================================================================================================
  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'EN';
  }

  // CustomFuntions ============================================================================================
  navToLanding():void{
    this.router.navigate(['/','landing']);
  }

  navToLogin():void{
    this.router.navigate(['/','login']);
  }

  navToHome():void{
    this.router.navigate(['/','home']);
  }


  selectLang(event:any):void{
    let selectedLang=event.target.value;
    localStorage.setItem('lang',selectedLang);

    this.translateService.use(selectedLang);
  }

  isLangingPage():boolean{
    return this.router.url.includes('landing');
  }

  authenticated():boolean{
    try{
      let access_token = this.cookieService.get('access_token');
      const decodedToekn = jwtDecode(access_token);
      const exp:any = decodedToekn.exp;
      const currentTiem = Math.floor(new Date().getTime() / 1000);
      return currentTiem < exp;
    }catch(error){
      return false
    }

  }

  alreadyInHome():boolean{
    return this.router.url.includes('home');
  }

  logOut(){
    this.authService.logOut();
    this.router.navigate(['/','landing'])
  }

}
